import React, { Component, useEffect, useState } from 'react'
import Suggest from './subComponents/Suggest';
import Story from './subComponents/Story';


function Right(){
    const [suggestData, setSuggestData] = useState([]);
    const fetchSuggestData = () => {
        fetch('https://kinkyfans.net/rn-adaptor/l-suggestions?id=1')
            .then(responseSGG => responseSGG.json())
            .then(suggestData => setSuggestData(suggestData))
            .catch(errorSGG => console.error('Fetch error : ', errorSGG));
        }
    useEffect(() => {
        fetchSuggestData();
    }, []);
    return (
        
        <div className='rightArea'>
            <div className='raSearch'>
                <input placeholder='Search creators'/>
            </div>
            <div className='raSuggestions'>
                <div className='rasTitle'>
                    <h4>Suggestions</h4>
                </div>
                <div className='rasUsers'>
                    <Suggest datas = {suggestData}/>
                </div>
            </div>
            
            <div className='rasStories'>
                <Story datas = {suggestData} />
            </div>
            <div className='rasPolicies'>
                <ul>
                    <li><a href=''>FAQ</a></li>
                    <li><a href=''>Privacy Policy</a></li>
                    <li><a href=''>Terms & Conditions</a></li>
                    <li><a href=''>Cookie Policy</a></li>
                </ul>
            </div>
        </div>
    )
}
export default Right;