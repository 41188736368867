import React, { Component, useEffect, useState } from 'react';
/*import '../assets/css/store.css';*/
import StoreHead from './subComponents/StoreHead';
import StoreFooter from './subComponents/StoreFooter';
function Store() {
    const [userData, setUserData] = useState([]);
    const pp = require('../assets/images/klogo.webp');
    let lastImg = "img1";
    function chImage(gid){
        document.getElementById(gid).classList.add('idlaSelected');document.getElementById(lastImg).classList.remove('idlaSelected');
        document.getElementById('mainImg').src = document.getElementById(gid).src;
        lastImg = gid;
    }
    return (
        <div className='storePage'>
            <StoreHead datas={StoreHead} />

            <div className='containers'>
                <div className='breadcrump'>
                    <a>Homepage</a> <a> > </a> <a>Cart</a>
                </div>
                <div className='shoppingCart'>
                    <div className='scLeft'>
                        <h1>Cart</h1>
                        <table>
                            <tr>
                                <td><b>#</b></td>
                                <td><b>Product Name</b></td>
                                <td><b>Price</b></td>
                                <td><b>Quantity</b></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td><img src={require('../assets/images/product1.webp')} /></td>
                                <td>
                                    <p>Product Name</p>
                                    <span>Black</span>
                                </td>
                                <td><p>€56.67</p></td>
                                <td><input type='number' value='1' /></td>
                                <td><p className='cartRemove'>x</p></td>
                            </tr>
                        </table>
                    </div>
                    <div className='scRight'>
                        <form className='scrCoupon'>
                            <p>Coupon Code</p>
                            <input placeholder='Coupon Code' />
                            <a>Submit</a>
                        </form>
                        <div className='scrTotals'>
                            <div className='scrTotal'>
                                <span>Total </span>
                                <p>56.55€</p>
                            </div>
                            <div className='scrTotal'>
                                <span>Coupon </span>
                                <p>10.00€</p>
                            </div>
                            <div className='scrTotal'>
                                <span>Shipping </span>
                                <p>0.00€</p>
                            </div>
                            <div className='scrTotal'>
                                <span>Total </span>
                                <p>46.55€</p>
                            </div>
                        </div>
                        <a className='scrCheckout'>Checkout</a>
                    </div>

                </div>

            </div>
            <StoreFooter datas={StoreFooter} />
        </div>
    )
}
export default Store;
