import React, { Component, useEffect, useState } from 'react';
/*import '../assets/css/store.css';*/
import StoreHead from './subComponents/StoreHead';
import StoreFooter from './subComponents/StoreFooter';
function Store() {
    const [userData, setUserData] = useState([]);
    const pp = require('../assets/images/klogo.webp');
    return (
        <div className='storePage'>
            <StoreHead datas={StoreHead} />

            <div className='containers'>
                <div className='breadcrump'>
                    <a>Homepage</a> <a> &#11146; </a> <a>Categories</a>
                </div>
                <div className='categoriesLeft'>
                    <h3>Filter</h3>
                    <div className='clCheckBox'>
                        <input type='checkbox' />
                        <span>In Stock</span>
                    </div>
                    <h3>Price</h3>
                    <div className='clPriceBox'>
                        <input placeholder='$ 0' type='number' />
                        <span>to</span>
                        <input placeholder='$ 9999' type='number' />
                    </div>
                    <h3>Meterial</h3>
                    <div className='clCheckBox'><input type='checkbox' /><span>Glass</span></div>
                    <div className='clCheckBox'><input type='checkbox' /><span>Leather</span></div>
                    <div className='clCheckBox'><input type='checkbox' /><span>Leather and Metal</span></div>
                    <div className='clCheckBox'><input type='checkbox' /><span>Metal</span></div>
                    <div className='clCheckBox'><input type='checkbox' /><span>Other</span></div>
                    <div className='clCheckBox'><input type='checkbox' /><span>Plastic</span></div>
                    <div className='clCheckBox'><input type='checkbox' /><span>Silicone</span></div>
                </div>
                <div className='categoriesRight'>
                    <h1>BDSM Special</h1>
                    <p>No matter what your role is in a power exchange, you’ll find the perfect piece to begin or add to your BDSM collection. Explore a collection of finely crafted restraints, impact tools, and other pain and pleasure-inducing devices.</p>
                    <img className='crBanner' src={require('../assets/images/storeBanner.webp')} />
                    <div className='crItems'>
                        <div className='crItem'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                    </div>
                    <div className='crItems'>
                        <div className='crItem'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                    </div>
                    <div className='crItems'>
                        <div className='crItem'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                    </div>
                    <div className='crItems'>
                        <div className='crItem'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                    </div>
                    <div className='crItems'>
                        <div className='crItem'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                    </div>
                    <div className='crItems'>
                        <div className='crItem'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                    </div>
                    <div className='crItems'>
                        <div className='crItem'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                    </div>
                    <div className='crItems'>
                        <div className='crItem'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                    </div>
                    <div className='crItems'>
                        <div className='crItem'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                    </div>
                    <div className='crItems'>
                        <div className='crItem'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                    </div>
                </div>

            </div>
            <StoreFooter datas={StoreFooter} />
        </div>
    )
}
export default Store;
