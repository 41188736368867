import React, { Component, useEffect, useState } from 'react'
import he from 'he';
import alertify from 'alertifyjs';
import Left from './Left';
import Right from './Right';
function opComment(id) {
    var cArea = document.getElementById('comment' + id);
    if (cArea.style.display == "block") {
        cArea.style.display = "none";
    } else {
        cArea.style.display = "block";
    }
}
function Main() {
    const pp = require('../assets/images/klogo.webp');
    const parser = new DOMParser();
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');
    let stockimg = localStorage.getItem('stockimg');
    //datas = this.props.datas;
    const [getPosts, setGetPosts] = useState([]);
    let type = "all";
    const fetchGetPosts = () => {
        fetch('https://kinkyfans.net/rn-adaptor/l-mainpage?&userid=' + userid + '&type=' + type)
            .then(responseGPo => responseGPo.json())
            .then(getPosts => setGetPosts(getPosts))
            .catch(errorGPo => console.error('Fetch error : ', errorGPo));
    }
    useEffect(() => {
        fetchGetPosts();
    }, []);
    console.log(getPosts);
    const postLike = async (userid, postid, dil) => {
        const data = { userid: userid, postid: postid, dil: dil };
        try {
            const response = await fetch('http://kinkyfans.net/rn-adaptor/o-postLike.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            console.log("PHP tarafından dönen yanıt:", plJson);
            fetchGetPosts();
        } catch (error) { console.log("An error :", error); }
    };
    const postBook = async (userid, postid, dil) => {
        const data = { userid: userid, postid: postid, dil: dil };
        try {
            const response = await fetch('http://kinkyfans.net/rn-adaptor/o-addBookmarkt.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const pbJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", pbJson);
            fetchGetPosts();
        } catch (error) { console.log("An error :", error); }
    };
    const [text, setText] = useState([]);
    const emojis = ["😀", "😁", "😅", "😄", "😂", "🤣", "🥰", "😍", "💑", "😎", "🤔", "😠", "😞", "😪", "😭", "🎈"];
    const addEmoji = (emoji) => { setText(text + emoji); }
    const textChange = (event) => { setText(event.target.value); }
    const sendComment = async (userid, postid, dil) => {
        const tArea = document.getElementById('commentText' + postid).value;
        const data = { userid: userid, postid: postid, dil: dil, comment: tArea };
        try {
            const response = await fetch('http://kinkyfans.net/rn-adaptor/o-sendComment.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            console.log("PHP tarafından dönen yanıt:", plJson);
            fetchGetPosts();
            alertify.success(plJson.message, 2);
            document.getElementById('commentText' + postid).value = "";
            setText('');
        } catch (error) { console.log("An error :", error); }
    };
    const sendTip = async (userid, dil) => {
        const tipArea = document.getElementById('tipAmount').value;
        const tipComment = document.getElementById('tipComment').value;
        const tipUser = document.getElementById('mtAreaUser').innerHTML;
        const tipPost = document.getElementById('mtAreaPost').innerHTML;
        const data = { userid: userid, dil: dil, tip: tipArea, comment: tipComment, ouser: tipUser, postid: tipPost };
        //console.log(data);
        try {
            const response = await fetch('http://kinkyfans.net/rn-adaptor/o-sendTip.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", plJson);
            fetchGetPosts();
            document.getElementById('modalTip').style.display = "none";
            document.getElementById('tipAmount').value = "";
            document.getElementById('tipComment').value = "";
            if (plJson.status == 0) { alertify.error(plJson.message, 2); }
            if (plJson.status == 1) { alertify.success(plJson.message, 2); }

        } catch (error) { console.log("An error :", error); }
    };
    const getTip = async (userid, postid) => {
        const data = { userid: userid };
        try {
            const response = await fetch('https://kinkyfans.net/rn-adaptor/o-getTip.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const gtJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", gtJson);
            document.getElementById('modalTip').style.display = "flex";
            document.getElementById('mtAreaImg').src = gtJson.pp;
            document.getElementById('mtAreaP').innerHTML = gtJson.name + ' ' + gtJson.surname;
            document.getElementById('mtAreaA').href = '/user-detail' + gtJson.userid;
            document.getElementById('mtAreaA').innerHTML = '@' + gtJson.username;
            document.getElementById('mtAreaUser').innerHTML = userid;
            document.getElementById('mtAreaPost').innerHTML = postid;
        } catch (error) { console.log("An error :", error); }
    };
    const buyPost = async (userid, postid, dil) => {
        const data = {userid : userid, postid : postid, dil : dil}
        try {
            const response = await fetch('https://kinkyfans.net/rn-adaptor/o-buyPost.php',{
                method : 'POST', headers: {'Content-Type': 'application/json',},body: JSON.stringify(data)
            });
            if(!response.ok){throw new Error('HTTP error! status : ${response.status}');}
            const bpJson = await response.json();
            if (bpJson.status == 0) { alertify.error(bpJson.message, 2); }
            if (bpJson.status == 1) { alertify.success(bpJson.message, 2); }
            fetchGetPosts();
            console.log(bpJson);
        }catch(error){console.log("An error : ", error);}
    }
    function chType(gType){
        document.getElementById(type+'B').classList.remove('midAreaFilterActive');
        document.getElementById(gType+'B').classList.add('midAreaFilterActive');
        type = gType;
        fetchGetPosts();
    }
    //console.log(getPosts);
    return (
        <div>
            <div className='leftAreaC'>
                <Left title="Title" />
            </div>
            <div className='fullAreaC'>
                <div className='midArea'>
                    <div id="modalTip" className="modalTip">
                        <a onClick={() => document.getElementById('modalTip').style.display = "none"} className='mmClose'></a>
                        <div className='mtArea'>
                            <h5>Send Tip</h5>
                            <span onClick={() => document.getElementById('modalTip').style.display = "none"}>x</span>
                            <div className='mtUserArea'>
                                <img id="mtAreaImg" src={stockimg} />
                                <p id="mtAreaP">Name Surname</p>
                                <a href={'/user-detail/1'} id='mtAreaA'>@username</a>
                            </div>
                            <p id='mtAreaUser'>0</p>
                            <p id='mtAreaPost'>0</p>
                            <div className='mtAmountArea'>
                                <p>Tip Amount</p>
                                <img src={require('../assets/icons/tip.webp')} />
                                <input id="tipAmount" placeholder='Enter Amount' />
                            </div>
                            <div className='mtDescArea'>
                                <p>Message</p>
                                <textarea id='tipComment'></textarea>
                            </div>
                            <div className='mtButtonArea'>
                                <a>Cancel</a>
                                <a onClick={() => sendTip(userid, dil)}>Send Tip</a>
                            </div>
                        </div>
                    </div>
                        <div className='midAreaC'>
                            <div className='midAreaTop'>
                                {getPosts ?(<h1>Home {getPosts.count}</h1>):(<h1>Home</h1>)}
                                <a href=''><img src={require('../assets/icons/threedots.webp')} alt={'Home'} /></a>
                            </div>
                            <div className='midAreaFilter'>
                                <a onClick={()=>chType('all')} id='allB' className='midAreaFilterActive'>All</a>
                                <a onClick={()=>chType('for_you')} id='for_youB'>For You</a>
                                <a onClick={()=>chType('purchases')} id='purchasesB'>Purchases</a>
                            </div>
                            {getPosts ? (
                            <div className='midAreaContents'>
                                {getPosts.map(product =>
                                    <div key={product.id} className='midAreaContent'>
                                        <div className='macUserInfo'>
                                            <img src={product.pp} alt={product.title} />
                                            <p>{product.name} {product.surname}</p>
                                            <a href={'/user-detail/' + product.userid}>@{product.username}</a>
                                        </div>
                                        <div className='macContentDesc'>
                                            <h6>{(parser.parseFromString(he.decode(product.title), 'text/html')).documentElement.textContent}</h6>
                                            {(parser.parseFromString(he.decode(product.description), 'text/html')).documentElement.textContent}
                                        </div>

                                        {product.image ? (
                                            <div className='macContentImage'>
                                                {product.cansee}
                                                <div>
                                                    {(() => {
                                                        if (product.cansee === 'see') {
                                                            return <a href={'/post/'+product.id}><img src={product.image} /></a>;
                                                        } else if (product.cansee === 'hide') {
                                                            return <div>
                                                                <img src={stockimg} />;
                                                                <div className='unLockPost'>
                                                                    {product.issubs ? (
                                                                        <a href={'/user-detail/' + product.userid}>Unlock the Content by Subscribe {product.username}</a>
                                                                    ) : (
                                                                        <a onClick={()=>{buyPost(userid, product.id, dil)}}>Unlock This Content for {product.price} $</a>
                                                                    )}

                                                                </div>
                                                            </div>
                                                        } else {
                                                            return <img src={stockimg} />
                                                        }
                                                    })()}
                                                </div>
                                            </div>
                                        ) : ('')}
                                        {product.video ? (
                                            <div className='macContentVideo'>
                                                {product.cansee == 'see' ? (
                                                    <div>
                                                        <video width="100%;" height="400px" controls controlsList="nodownload"><source src={product.video} type="video/mp4"/></video>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <img src={stockimg} />;
                                                        <div className='unLockPost'>
                                                            {product.issubs > 0 ? (
                                                                <a href={'/user-detail/' + product.userid}>Unlock the Content by Subscribe {product.username}</a>
                                                            ) : (
                                                                <a onClick={()=>{buyPost(userid, product.id, dil)}}>Unlock This Content for {product.price} $</a>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : ('')}
                                        {product.ares2 ? (
                                            <div className='macContentImages'>
                                                {product.cansee == 'see' ? (
                                                    <img src={product.ares2} />
                                                ) : (
                                                    <img src={stockimg} />
                                                )}

                                                {(() => {
                                                    if (product.cansee === 'see' && product.ares3 != '') {
                                                        return <img src={product.ares3} />;
                                                    } else if (product.cansee === 'hide') {
                                                        return <img src={stockimg} />
                                                    } else {
                                                        return <img src={stockimg} />
                                                    }
                                                })()}
                                                {(() => {
                                                    if (product.cansee === 'see' && product.ares4 != '') {
                                                        return <img src={product.ares4} />;
                                                    } else if (product.cansee === 'hide') {
                                                        return <img src={stockimg} />
                                                    } else {
                                                        return <img src={stockimg} />
                                                    }
                                                })()}

                                            </div>
                                        ) : ('')}
                                        <div className='macContentFunctions'>
                                            {product.ulike > 0 ? (
                                                <a onClick={() => postLike(userid, product.id, dil)} className='macContentFunction'><img src={require('../assets/icons/heartdoll.webp')} /><p>{product.like}</p></a>
                                            ) : (
                                                <a onClick={() => postLike(userid, product.id, dil)} className='macContentFunction'><img src={require('../assets/icons/heart.webp')} /><p>{product.like}</p></a>
                                            )}
                                            <a onClick={() => opComment(product.id)} className='macContentFunction'><img src={require('../assets/icons/comment.webp')} /></a>
                                            <a onClick={() => { getTip(product.userid, product.id, product.pp, product.username, product.name, product.surname) }} className='macContentFunction'><img src={require('../assets/icons/tip.webp')} /></a>
                                            {product.ubook > 0 ? (
                                                <a onClick={() => postBook(userid, product.id, dil)} className='macContentBookmark'><img src={require('../assets/icons/bookmarkdoll.webp')} /></a>
                                            ) : (
                                                <a onClick={() => postBook(userid, product.id, dil)} className='macContentBookmark'><img src={require('../assets/icons/bookmark.webp')} /></a>
                                            )}
                                            <div id={"tipArea" + product.id} className='macContentTipArea'>
                                                <select id={"tipSelect" + product.id}>
                                                    <option value="1">1$</option>
                                                    <option value="2">2$</option>
                                                    <option value="5">5$</option>
                                                    <option value="10">10$</option>
                                                    <option value="20">20$</option>
                                                    <option value="50">50$</option>
                                                </select>
                                                <a onClick={() => sendTip(userid, product.id, dil)}>Send</a>
                                            </div>
                                        </div>
                                        <div id={'comment' + product.id} className='macContentComments'>
                                            {product.comments ? (
                                                <div>
                                                    {product.comments.map(comment =>
                                                        <div key={comment.id} className='macContentComment'>
                                                            <img src={comment.pp} />
                                                            <p>{comment.name} {comment.surname}</p>
                                                            <a href={'/user-dteail/' + comment.id}>@{comment.username}</a>
                                                            <div className='macContentCommentData'>
                                                                {comment.comment}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : ('')}
                                            <div className='macContentAddComment'>
                                                <h5>Add Comment</h5>
                                                <div id={"emoji" + product.id} className='macContentEmojiArea'>
                                                    {emojis.map((emoji) => (
                                                        <a key={emoji} onClick={() => addEmoji(emoji)} style={{ margin: '5px' }}>{emoji}</a>
                                                    ))}
                                                </div>

                                                <div className='macContentTextArea'>
                                                    <textarea id={'commentText' + product.id} value={text} onChange={textChange} rows="4" cols="50"></textarea>
                                                    <a onClick={() => { document.getElementById('emoji' + product.id).style.display === "block" ? (document.getElementById('emoji' + product.id).style.display = "none") : (document.getElementById('emoji' + product.id).style.display = "block") }
                                                    } className='emoji'><img src={require("../assets/icons/emoji.webp")} /></a>
                                                    <a onClick={() => sendComment(userid, product.id, dil)} className='send'><img src={require("../assets/icons/send.webp")} /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            ):(
                                <div className='midAreaContent'>
                                    <div className='midAreaFilter'>
                                        <p>No post..</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    <div className='rightAreaC'>
                        <Right />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Main;