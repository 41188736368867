import React, { Component, useEffect, useState } from 'react'
import { ScrollRestoration, UNSAFE_DataRouterStateContext, useParams } from 'react-router-dom';
import he from 'he';
import alertify from 'alertifyjs';
import Left from './Left';
function Posts({ match }) {
    let { uniqid } = useParams();
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');
    const [getPosts, setGetPosts] = useState([]);
    const [getPost, setGetPost] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);
    //const [selectedFiles, setSelectedFiles] = useState([]);
    const parser = new DOMParser();
    let type = "all";
    let order = "desc";
    function orderChange(gOrder){
        if(gOrder == "desc"){
            document.getElementById('otcDESC').style.display="none";
            document.getElementById('otcASC').style.display="block";
            order = "desc";
        }
        if(gOrder == "asc"){
            document.getElementById('otcDESC').style.display="block";
            document.getElementById('otcASC').style.display="none";
            order = "asc";
        }
        fetchGetPosts();
    }
    function typeChange(gType){
        document.getElementById('typeAll').classList.remove('amSelectActive');
        document.getElementById('typeActive').classList.remove('amSelectActive');
        document.getElementById('typePassive').classList.remove('amSelectActive');
        if(gType == "all"){document.getElementById('typeAll').classList.add('amSelectActive');type = "all";fetchGetPosts();}
        if(gType == "Active"){document.getElementById('typeActive').classList.add('amSelectActive');type = "Aktif";fetchGetPosts();}
        if(gType == "Passive"){document.getElementById('typePassive').classList.add('amSelectActive');type = "Waiting";fetchGetPosts();}
    }
    const fetchGetPosts = () => {
        fetch('https://kinkyfans.net/rn-adaptor/l-myPosts.php?&userid=' + userid + '&type=' + type + '&lang=' + dil + '&order=' + order)
            .then(responsePosts => responsePosts.json())
            .then(getPosts => setGetPosts(getPosts),)
            .catch(errorPosts => console.error('Fetch error : ', errorPosts));            
    }
    const fetchGetPost = () => {
        fetch('https://kinkyfans.net/rn-adaptor/functions.php?islem=getPost&userid=' + userid + '&uniqid=' + uniqid + '&lang=' + dil)
            .then(responsePost => responsePost.json())
            .then(getPost => setGetPost(getPost), setText(getPost.description))
            .catch(errorPost => console.error('Fetch error : ', errorPost));
    }
    useEffect(() => {
        fetchGetPosts();
        fetchGetPost();
    }, []);
    //console.log(getPost);
    let lastArea = "eaUsers";
    function opArea(area) {
        document.getElementById(lastArea + "Tab").classList.remove('ntfFilterActive');
        document.getElementById(area + "Tab").classList.add('ntfFilterActive');
        lastArea = area;
    }
    let stockimg = sessionStorage.getItem('stockimg');
    function showTrash(msgid) { document.getElementById('trashArea' + msgid).style.display = 'flex'; document.getElementById('trashArea' + msgid).style.opacity = '1';}
    function hideTrash(msgid) { document.getElementById('trashArea' + msgid).style.display = 'flex'; document.getElementById('trashArea' + msgid).style.opacity = '0';}

    const [text, setText] = useState([]);
    const emojis = ["😀", "😁", "😅", "😄", "😂", "🤣", "🥰", "😍", "💑", "😎", "🤔", "😠", "😞", "😪", "😭", "🎈"];
    const addEmoji = (emoji) => { setText(text + emoji); }
    const textChange = (event) => { setText(event.target.value); }
    
    
    let lastPostType = "imagePost";
    function postType(area) {
        document.getElementById(lastPostType + 'A').classList.remove('mypiaTabActive');
        document.getElementById(area + 'A').classList.add('mypiaTabActive');
        lastPostType = area;
        document.getElementById('imagePostArea').style.display = "none"; 
        document.getElementById('videoPostArea').style.display = "none";
        document.getElementById(area + 'Area').style.display = "block";
    }
    function mClose() {
        document.getElementById('modalMedia').style.display = "none";
        document.querySelector("#modalVideo").pause();
    }
    const openMedia = async (road, type) => {
        document.getElementById('modalMedia').style.display = "flex";
        if (type == "video") {
            document.getElementById('mmDatasImage').style.display = "none";
            document.getElementById('mmDatasVideo').style.display = "flex";
            document.getElementById('modalVideo').src = road;
        } else {
            document.getElementById('mmDatasImage').style.display = "flex";
            document.getElementById('mmDatasVideo').style.display = "none";
            document.getElementById('modalImage').src = road;
        }
    };
    //console.log(getPost);
    let sendPostType = "";
    if(getPost.video){sendPostType = "video";}else{sendPostType="image";}
    const handleImageChange = (event) => { 
        console.log(event.target.files[0]);
        setSelectedFile(event.target.files[0]); 
        let dosya = event.target.files[0];
        handleSubmit(dosya);
        //console.log(setSelectedFile);
        document.getElementById('videoPostText').display = 'block';  
        document.getElementById('videoPostText').innerHTML = 'Uploading..';  
    };
    const handleSubmit = (file) => {
        console.log(file);
        if (selectedFile) {
            console.log('Image selected' + selectedFile)
            const formData = new FormData();
            formData.append('file', file); formData.append('userid', userid);formData.append('uniqid', uniqid);
            for (let [key, value] of formData.entries()) {
                if (value instanceof File) {
                    console.log(`${key}: ${value.name}, ${value.size}, ${value.type}`);
                } else {
                    console.log(`${key}: ${value}`);
                }
            }
            if(getPost.video){
                //alert('video');
                fetch('http://kinkyfans.net/rn-adaptor/functions.php?islem=postVideoAdd', { method: 'POST', body: formData })
                .then(response => { console.log(response.json());
                fetchGetPost(); })
                .catch(error => { console.log(error); });
            }else{
                if(lastPostType == "videoPost"){
                    //alert('video');
                    fetch('http://kinkyfans.net/rn-adaptor/functions.php?islem=postVideoAdd', { method: 'POST', body: formData })
                    .then(response => { console.log(response.json());
                    fetchGetPost(); })
                    .catch(error => { console.log(error); });
                }else{
                    //alert('image');
                    fetch('http://kinkyfans.net/rn-adaptor/functions.php?islem=postImageAdd', { method: 'POST', body: formData }).
                    then(response => { console.log(response); fetchGetPost(); })
                    .catch(error => { console.log(error); });
                }
            }
            
        }
    };
    const subFunc = async (userid, id, func) => {
        let raw = document.getElementById('subImage' + id).value;
        const subData = new FormData();
        subData.append('userid', userid); subData.append('id', id); subData.append('func', func); subData.append('raw', raw);
        console.log(subData)
        fetch('http://kinkyfans.net/rn-adaptor/functions.php?islem=subImageFunc', { method: 'POST', body: subData }).then(response => { console.log(response); fetchGetPost(); }).catch(error => { console.log(error); });
    };
    const delPost = async (userid, id) => {
        const subData = new FormData();
        subData.append('userid', userid); subData.append('id', id);
        console.log(subData)
        fetch('http://kinkyfans.net/rn-adaptor/functions.php?islem=delPost', { method: 'POST', body: subData }).then(response => { console.log(response); fetchGetPost(); }).catch(error => { console.log(error); });
        fetchGetPosts();
    };
    const addFunc = async (userid) => {
        let title = document.getElementById('title').value;
        let category = document.getElementById('category').value;
        let price = document.getElementById('price').value;
        let subsonay = document.getElementById('subsonay').value;
        let bio = document.getElementById('bio').value;
        const addData = new FormData();
        addData.append('userid', userid);
        addData.append('title', title);
        addData.append('category', category);
        addData.append('price', price);
        addData.append('subsonay', subsonay);
        addData.append('bio', bio);
        addData.append('uniqid', uniqid);
        console.log(addData)
        updateProgress("Updating...");
        fetch('http://kinkyfans.net/rn-adaptor/functions.php?islem=addPostFunc', { method: 'POST', body: addData }).then(response => {updateProgress("Completed."); console.log(response); fetchGetPost(); }).catch(error => { throw new Error('Error');console.log(error); });
        window.location.href='/posts';
    };

    function updateProgress(message) {
        document.getElementById('updateP').style.display = "block";
        document.getElementById('updateP').value = message;
        document.getElementById('updateP').innerHTML = message;
        console.log(message);
    }
    const titleChange = (event) => {setGetPost({title:  document.getElementById('title').value});fetchGetPost(); /*dataChange('title', document.getElementById('title').value);*/};
    const categoryChange = (event) => {setGetPost({title:  document.getElementById('title').value});fetchGetPost();};
    const priceChange = (event) => {setGetPost({price:  document.getElementById('price').value});fetchGetPost();};
    const subsChange = (event) => {setGetPost({subsonay:  document.getElementById('subsonay').value});fetchGetPost();};
    let yuklendimi = 0;
    //let biotext = (he.decode(getPost.description), 'text/html');
    setTimeout(function () {if(yuklendimi == 0){yuklendimi=1;if(getPost.description){setText((parser.parseFromString(he.decode(getPost.description), 'text/html')).documentElement.textContent);}};
    if(getPost.video){document.getElementById('videoPostArea').style.display="block";}
    }, 300);
    return (
        <div>
            <div id='modalMedia' className='modalMedia'>
                <a onClick={() => mClose()} className='mmClose'></a>
                <div className='mmDatas'>
                    {/*<a onClick={() => prevMedia()} className='mmDatasLeft' id='mmDatasLeft'><img src={require('../assets/icons/arrow.webp')} /></a>*/}
                    <div className='mmDatasImage' id='mmDatasImage'>
                        <img id='modalImage' src={stockimg} />
                    </div>
                    <div className='mmDatasVideo' id='mmDatasVideo'>
                        <video id='modalVideo' controls><source id='modalVideoSrc' src={stockimg} type="video/mp4" /></video>
                    </div>
                    {/*<a onClick={() => nextMedia()} className='mmDatasRight' id='mmDatasRight'><img src={require('../assets/icons/arrow.webp')} /></a>*/}
                </div>
            </div>
            <div className='leftAreaC'>
                <Left title="Title" />
            </div>
            <div className='fullAreaC'>
                <div className='allMessages'>
                    <div className='amHeader'>
                        <h3>My Posts</h3>
                    </div>
                    <div className='amFilter'>
                        <p id='orderText'>Latest First</p>
                        <img id='otcASC' onClick={()=>orderChange('asc')} src={require('../assets/icons/filter.webp')} />
                        <img id='otcDESC' onClick={()=>orderChange('desc')} src={require('../assets/icons/filter.webp')} />
                    </div>
                    <div className='amSelect'>
                        <a id='typeAll' className='amSelectActive' onClick={()=>typeChange('all')}>All</a>
                        <a id='typeActive' onClick={()=>typeChange('Active')}>Approved</a>
                        <a id='typePassive' onClick={()=>typeChange('Passive')}>Awaiting Approval</a>
                    </div>
                    {getPosts ? (
                        <div>
                            {getPosts.map(posts =>
                                <div key={posts.id} onMouseOver={() => showTrash(posts.id)} onMouseOut={() => hideTrash(posts.id)} className='myPosts'>
                                    {posts.video ? (<video width="100%;" height="150px" ><source src={posts.video} type="video/mp4" /></video>):(<img className='mypImage' src={posts.image} />)}
                                    <div className='mypCover'></div>
                                    <div className='mypTitle'>
                                        <a href={'/user-detail/' + posts.otherid}>{((parser.parseFromString(he.decode(posts.title), 'text/html')).documentElement.textContent)}</a>
                                    </div>
                                    <div id={"trashArea" + posts.id} className='mypFunc'>
                                        <a href={'/posts/'+posts.id}><img src={require('../assets/icons/settings.webp')} /></a>
                                        <a onClick={()=>delPost(userid, posts.id)}><img src={require('../assets/icons/trash.webp')} /></a>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : ('No Posts Yet')}
                </div>
                <div className='nowMessages'>
                    <div id='newMessageArea'>
                        <div className='amHeader'>
                            <h3>New Post</h3>
                        </div>
                        <div className='mypInputAreas'>
                            <h4>Post Title</h4>
                            <div className='mypInputArea mypHalf'>
                                <label>Title</label>
                                {getPost.title?(
                                <input id='title' value={(parser.parseFromString(he.decode(getPost.title), 'text/html')).documentElement.textContent} onChange={()=>titleChange()}/>
                                ):('')}
                            </div>
                            <div className='mypInputArea mypHalf'>
                                <label>Category</label>
                                {getPosts[0] ? (
                                    <select value={getPost.category} id="category" onChange={()=>categoryChange()}>
                                        {getPosts[0].category.map(category =>
                                            <option key={category.id} value={category.id}>{category.title}</option>
                                        )}
                                    </select>
                                ) : ''}
                            </div>
                            <div className='mypInputArea mypHalf'>
                                <label>Price</label>
                                <input id='price' value={getPost.price} onChange={()=>priceChange()}/>
                            </div>
                            <div className='mypInputArea mypHalf'>
                                <label>Can subscribers See</label>
                                <select id="subsonay"  value={getPost.subsonay} onChange={()=>subsChange()}>
                                    <option value='0'>No</option>
                                    <option value='1'>Yes</option>
                                </select>
                            </div>
                            <div className='mypInputArea'>
                                <div id={"emoji1"} className='mypdEmojiArea'>
                                    {emojis.map((emoji) => (
                                        <a key={emoji} onClick={() => addEmoji(emoji)} style={{ margin: '5px' }}>{emoji}</a>
                                    ))}
                                </div>
                                <div className='mypdArea'>
                                    <label>Post Description</label>
                                    {/*<div>{getPost.description ?(setText(he.decode(getPost.description), 'text/html')):('')};</div>*/}
                                    <textarea id={'bio'} value={text} onChange={textChange} onLoad={()=>alert('here')} placeholder='Enter your description'></textarea>
                                    <a onClick={() => { document.getElementById('emoji1').style.display === "block" ? (document.getElementById('emoji1').style.display = "none") : (document.getElementById('emoji1').style.display = "block") }
                                    } className='emoji'><img src={require("../assets/icons/emoji.webp")} /></a>
                                </div>
                            </div>
                            <div className='mypImageArea'>
                                {uniqid>0 ?(
                                    ''
                                ):(
                                    <div className='mypiaTab'>
                                        <a id='imagePostA' className='mypiaTabActive' onClick={() => postType('imagePost')}>Image Post</a>
                                        <a id='videoPostA' onClick={() => postType('videoPost')}>Video Post</a>
                                    </div>
                                )}
                                {uniqid > 0 ?(
                                <div>
                                    {getPost.video ?(
                                    <div id='videoPostArea'>
                                        <a onClick={() => document.getElementById('newVideo').click()}>Add New Video</a>
                                        <input type="file" onChange={(events) => { handleImageChange(events); }} className='hidden' id='newVideo' value={''} />
                                        <p id='videoPostText' className='hidden'>Video</p>
                                        <video id='modalVideo' controls><source id='modalVideoSrc' src={getPost.video} type="video/mp4" /></video>
                                    </div>
                                    ):(
                                    <div id='imagePostArea'>
                                        <a onClick={() => document.getElementById('newImage').click()}>Add New Image</a>
                                        <input type="file" onChange={(event) => { handleImageChange(event); }} className='hidden' id='newImage' />
                                        <div className='ipaImages'>
                                            {getPost.images ? (
                                                <div className='ipaImageDiv'>
                                                    {getPost.images.map(images =>
                                                        <div key={images.id} className='ipaImage'>
                                                            <img onClick={() => { openMedia(images.image, 'image') }} id={'subImage'+images.id} src={images.image} />
                                                            {images.row == 0 ? (
                                                                <div>
                                                                    <p>Main</p>
                                                                    <a onClick={() => subFunc(userid, images.id, 'del')} className='setMainDelete'>Delete</a>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <input type='number' onChange={() => subFunc(userid, images.id, 'change')} id={'subImage' + images.id} value={images.row} />
                                                                    <a onClick={() => subFunc(userid, images.id, 'set')} className='setMain'>Set Main</a>
                                                                    <a onClick={() => subFunc(userid, images.id, 'del')} className='setMainDelete'>Delete</a>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : ('')}
                                        </div>
                                    </div>
                                    )}
                                </div>
                                ):(
                                <div>
                                    <div id='videoPostArea'>
                                        <a onClick={() => document.getElementById('newVideo').click()}>Add New Video</a>
                                        <input type="file" onChange={(events) => { handleImageChange(events); }} className='hidden' id='newVideo' value={''} />
                                        <p id='videoPostText' className='hidden'>Video</p>
                                        {uniqid > 0 ?(<video id='modalVideo' controls><source id='modalVideoSrc' src={getPost.video} type="video/mp4" /></video>):('')}
                                    </div>
                                    <div>
                                        <div id='imagePostArea'>
                                            <a onClick={() => document.getElementById('newImage').click()}>Add New Image</a>
                                            <input type="file" onChange={(event) => { handleImageChange(event); }} className='hidden' id='newImage' />
                                            <div className='ipaImages'>
                                                {getPost.images ? (
                                                    <div className='ipaImageDiv'>
                                                        {getPost.images.map(images =>
                                                            <div key={images.id} className='ipaImage'>
                                                                <img onClick={() => { openMedia(images.image, 'image') }} id={'subImage'+images.id} src={images.image} />
                                                                {images.row == 0 ? (
                                                                    <div>
                                                                        <p>Main</p>
                                                                        <a onClick={() => subFunc(userid, images.id, 'del')} className='setMainDelete'>Delete</a>
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <input type='number' onChange={() => subFunc(userid, images.id, 'change')} id={'subImage' + images.id} value={images.row} />
                                                                        <a onClick={() => subFunc(userid, images.id, 'set')} className='setMain'>Set Main</a>
                                                                        <a onClick={() => subFunc(userid, images.id, 'del')} className='setMainDelete'>Delete</a>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                ) : ('')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                                <div className='postUpdateArea'>
                                    <a onClick={()=>addFunc(userid)}>Save</a>
                                    <p id='updateP'></p>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Posts;