import React, { Component, useEffect, useState } from 'react';
import alertify from 'alertifyjs';
import { useParams } from 'react-router-dom';
function Left() {
    var userid = localStorage.getItem('userid');
    let { guserid } = useParams();
    const [userData, setUserData] = useState([]);
    const fetchUserData = () => {
        fetch('https://kinkyfans.net/rn-adaptor/l-user?id=' + userid)
            .then(responseUD => responseUD.json())
            .then(userData => setUserData(userData))
            .catch(errorUD => console.error('Fetch error : ', errorUD));
    }
    useEffect(() => {
        //console.log(window.location.pathname);
        fetchUserData();
        if (guserid) {
            const getLogin = async (guserid) => {
                const data = { guserid: guserid };
                try {
                    const response = await fetch('http://kinkyfans.net/rn-adaptor/functions.php?islem=loginsor', {
                        method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
                    });
                    if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
                    const plJson = await response.json();
                    if (plJson.status == 0) {
                        alertify.error(plJson.message, 2);
                        console.log('LogError');
                        window.location.href = "/login"; 
                    }
                    if (plJson.status == 1) {
                        localStorage.removeItem('userid');
                        localStorage.setItem('userid', plJson.userid);
                        var userid = localStorage.getItem('userid');
                        alertify.success(plJson.message, 2);
                        fetchUserData();
                        window.location.href = "/";
                    }
                    //console.log(plJson);
                } catch (error) { console.log("An error :", error); }
            };
            getLogin(guserid);
        }
        if (userid > 0 || guserid > 0) {
            userid = localStorage.getItem('userid');
        } else {
            console.log('login error');
            setInterval(() => {
                //console.log("userid : "+userid);
                window.location.href = "/login";
            }, 1000);
        }
    }, []);
    let homeClass = "";
    let exploreClass = "";
    let notificationClass = "";
    let messageClass = "";
    let collectionsClass = "";
    let transactionsClass = "";
    let userDetailClass = "";
    if (window.location.pathname == "/") { homeClass = 'laMenuActive'; }
    if (window.location.pathname == "/explore") { exploreClass = 'laMenuActive'; }
    if (window.location.pathname == "/notification") { notificationClass = 'laMenuActive'; }
    if (window.location.pathname == "/message") { messageClass = 'laMenuActive'; }
    if (window.location.pathname == "/collections") { collectionsClass = 'laMenuActive'; }
    if (window.location.pathname == "/user-detail/"+userid) { userDetailClass = 'laMenuActive'; }
    const pp = require('../assets/images/klogo.webp');
    function ldMenu(type) {
        if (type == "close") {
            document.getElementById('ldMenu').style.left = "-300px";
            document.getElementById('windowBlur').style.display = "none";
        } else {
            document.getElementById('ldMenu').style.left = "0";
            document.getElementById('windowBlur').style.display = "block";
        }
    }
    //const pp = this.props.pp;
    return (
        <div className='leftArea'>
            <div className='laLogo'>
                <img src={pp} />
            </div>
            <div className='laUser'>
                <div className='laUserInfo'>
                    <img src={userData.pp} />
                    <p>{userData.name} {userData.surname}</p>
                    <a>@{userData.username}</a>
                </div>
                <a href='' className='laUserData'>
                    <h6>{userData.following}</h6>
                    <p>Following</p>
                </a>
                <a href='' className='laUserData'>
                    <h6>{userData.followers}</h6>
                    <p>Followers</p>
                </a>
            </div>
            <ul className="laMenu">
                <li><a href="/"><img className={homeClass}  src={require('../assets/icons/home.webp')} /> <p> Home</p></a></li>
                <li><a href="/explore" ><img className={exploreClass} src={require('../assets/icons/explore.webp')} /> <p> Explore</p></a></li>
                <li><a href="/notification"><img className={notificationClass} src={require('../assets/icons/notifications.webp')} /> <p> Notifications</p></a></li>
                <li><a href="/message"><img className={messageClass}  src={require('../assets/icons/message.webp')} /> <p> Messages</p></a></li>
                <li><a href="/collections"><img className={collectionsClass}  src={require('../assets/icons/collections.webp')} /> <p> Collections</p></a></li>
                <li><a href="/transactions"><img className={transactionsClass} src={require('../assets/icons/money.webp')} /> <p> Transactions</p></a></li>
                <li><a href={"/user-detail/" + userData.id}><img className={userDetailClass} src={require('../assets/icons/profile.webp')} /> <p> My Profile</p></a></li>
                <li><a onClick={() => ldMenu('open')}><img src={require('../assets/icons/more.webp')} /> <p> More</p></a></li>
            </ul>
        </div>
    )
}
export default Left;
