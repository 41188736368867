import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Left from './Left';
import he from 'he';
const parser = new DOMParser();
function Notification({ match }) {
    let { uniqid } = useParams();
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');

    const [getData, setGetData] = useState([]);
    const [getTra, setGetTra] = useState([]);
    let fetchType = "all";
    const fetchGetMessage = () => {
        fetch('https://kinkyfans.net/rn-adaptor/l-transactions.php?&userid=' + userid + '&dil=' + dil + '&type=' + fetchType)
            .then(responseData => responseData.json())
            .then(getData => setGetData(getData))
            .catch(errorData => console.error('Fetch error : ', errorData));
    }
    useEffect(() => {
        fetchGetMessage();
    }, []);
    const fetchGetTra = () => {
        fetch('https://kinkyfans.net/rn-adaptor/functions.php?islem=getTra&userid=' + userid + '&dil=' + dil)
            .then(responseTra => responseTra.json())
            .then(getTra => setGetTra(getTra))
            .catch(errorTra => console.error('Fetch error : ', errorTra));
    }
    useEffect(() => {
        fetchGetMessage();
        fetchGetTra();
    }, []);
    console.log(getTra);
    //console.log(getData);

    let stockimg = localStorage.getItem('stockimg');
    const parser = new DOMParser();
    let lastMenu = 'wallet';
    function changeMenu(gMenu) {
        if (gMenu != lastMenu) {
            document.getElementById(gMenu).style.display = "block"; document.getElementById(lastMenu).style.display = "none";
            document.getElementById(lastMenu + 'A').classList.remove('atBodyActive'); document.getElementById(gMenu + 'A').classList.add('atBodyActive');
            lastMenu = gMenu;
        }
    }
    let lastSelect = 'selectAll';
    function changeSelect(gSelect) {
        document.getElementById("selectAll").style.display = "none";
        document.getElementById("selectSub").style.display = "none";
        document.getElementById("selectMsg").style.display = "none";
        document.getElementById("selectPos").style.display = "none";
        document.getElementById("selectTip").style.display = "none";
        document.getElementById("selectFun").style.display = "none";
        document.getElementById(lastSelect).style.display = "none";
        document.getElementById(gSelect).style.display = "block";
        document.getElementById(lastSelect + 'S').classList.remove('stSelectActive');
        document.getElementById('selectAllS').classList.remove('stSelectActive');
        document.getElementById('selectSubS').classList.remove('stSelectActive');
        document.getElementById('selectMsgS').classList.remove('stSelectActive');
        document.getElementById('selectPosS').classList.remove('stSelectActive');
        document.getElementById('selectTipS').classList.remove('stSelectActive');
        document.getElementById('selectFunS').classList.remove('stSelectActive');
        document.getElementById(gSelect + 'S').classList.add('stSelectActive');
        lastSelect = gSelect;

        fetchType = gSelect;
        fetchGetMessage();
        //alert(gSelect + ' - '+lastSelect);
    }
    let lastTraType = "traSuccess";
    function changeTraType(gTraType) {
        document.getElementById('traSuccess').style.display = "none";
        document.getElementById('traError').style.display = "none";
        document.getElementById(lastTraType).style.display = "none"; document.getElementById(gTraType).style.display = "block";
        document.getElementById(lastTraType + 'S').classList.remove('stFilterActive'); document.getElementById(gTraType + 'S').classList.add('stFilterActive');
        lastTraType = gTraType;

    }
    let lastPrcType = "prcSuccess";
    function changeTraType2(gPrcType) {
        if (gPrcType != lastPrcType) {
            document.getElementById(lastPrcType).style.display = "none"; document.getElementById(gPrcType).style.display = "block";
            document.getElementById(lastPrcType + 'S').classList.remove('stFilterActive'); document.getElementById(gPrcType + 'S').classList.add('stFilterActive');
            lastPrcType = gPrcType;
        }
    }
    let lWallet = "myWallet";
    function chWallet(gWallet) {
        document.getElementById(lWallet).style.display = "none"; document.getElementById(gWallet).style.display = "block";
        document.getElementById(gWallet + 'S').classList.remove('stFilterActive'); document.getElementById(lWallet + 'S').classList.add('stFilterActive');
        lWallet = gWallet;
    }
    let lAM = "amCredit";
    function chAM(gAM) {
        document.getElementById(gAM).style.display = "none"; document.getElementById(lAM).style.display = "block";
        document.getElementById(gAM + 'S').classList.remove('stFilterActive'); document.getElementById(lAM + 'S').classList.add('stFilterActive');
        lAM = gAM;
    }
    return (
        <div>
            <div className='leftAreaC'>
                <Left title="Title" />
            </div>
            <div className='fullAreaC'>
                <div className='allTra'>
                    <div className='atHeader'>
                        <h3>Transactions</h3>
                    </div>
                    <div className='atBody'>
                        <a onClick={() => { changeMenu('wallet'); }} className='atBodyActive' id='walletA'>My Wallet<img src={require('../assets/icons/arrow.webp')} /></a>
                        <a onClick={() => { changeMenu('traHis'); }} id='traHisA'>Transaction History<img src={require('../assets/icons/arrow.webp')} /></a>
                        <a onClick={() => { changeMenu('traPurc'); }} id='traPurcA'>Purchases <img src={require('../assets/icons/arrow.webp')} /></a>
                        {/*<a>My Cards <img src={require('../assets/icons/arrow.webp')} /></a>*/}
                    </div>
                </div>
                <div id='wallet' className='selectTra'>
                    <div className='stHeader'>
                        <a className='leftArrow'><img src={require('../assets/icons/leftarrow.webp')} /></a>
                        <h3>My Wallet</h3>
                    </div>
                    <div className='stFilter'>
                        <a onClick={() => chWallet('myWallet')} id='myWalletS' className='stFilterActive'>Wallet</a>
                        <a onClick={() => chWallet('addMoney')} id='addMoneyS'>Add Money</a>
                        <a onClick={() => chWallet('withrawMoney')} id='withrawMoneyS'>Withraw Money</a>
                    </div>
                    <div id='myWallet' className='myWallet'>
                        <div className='myWalletCredit'>
                            <p>My Wallet Credits : <span>999€</span></p>
                            {getTra ? (
                                <div>
                                    <p>Pending Transactions</p>
                                    <table>
                                        <tr>
                                            <td>ID</td>
                                            <td>Description</td>
                                            <td>Total</td>
                                            <td>Status</td>
                                        </tr>
                                        {getTra.map(tras =>
                                        <tr>
                                            <td>{tras.id}</td>
                                            <td>{tras.desc}</td>
                                            <td>{tras.total}€</td>
                                            <td>{tras.status}</td>
                                        </tr>
                                        )}
                                    </table>
                                </div>
                            ) : ''}
                        </div>
                    </div>
                    <div id='addMoney' className='hidden'>
                        <div className='stFilter'>
                            <a onClick={() => chAM('amCredit')} className='stFilterActive' id='amCreditS'>Credit Card</a>
                            <a onClick={() => chAM('amBank')} id='amBankS'>Bank Transfer</a>
                        </div>
                        <div className='myWallet' id='amCredit'>
                            <p className='taC wmt-20'>You can use your credit card on CCBill</p>
                            <a className='ccbillButton'>Pay with CCbill</a>
                        </div>
                        <div className='myWallet hidden' id='amBank'>
                            <p className='taC wmt-20'>You can use our bank numbers for your transfer</p>
                            <a className='ccbillButton'>Pay with CCbill</a>
                        </div>
                    </div>
                    <div id='withrawMoney' className='hidden'>
                        withraw money
                    </div>
                </div>
                <div id='traHis' className='selectTra'>
                    <div className='stHeader'>
                        <a className='leftArrow'><img src={require('../assets/icons/leftarrow.webp')} /></a>
                        <h3>Transaction History</h3>
                    </div>
                    <div className='stFilter'>
                        <a onClick={() => changeTraType('traSuccess')} id='traSuccessS' className='stFilterActive'>Success</a>
                        <a onClick={() => changeTraType('traError')} id='traErrorS'>Error</a>
                    </div>
                    <div className='stSelect'>
                        <a onClick={() => { changeSelect('selectAll'); }} id='selectAllS' className='stSelectActive'>All</a>
                        <a onClick={() => { changeSelect('selectSub'); }} id='selectSubS'>Subscriptions</a>
                        <a onClick={() => { changeSelect('selectMsg'); }} id='selectMsgS'>Messages</a>
                        <a onClick={() => { changeSelect('selectPos'); }} id='selectPosS'>Posts</a>
                        <a onClick={() => { changeSelect('selectTip'); }} id='selectTipS'>Tips</a>
                        <a style={{ display: "none" }} onClick={() => { changeSelect('selectFun'); }} id='selectFunS'>Funds</a>
                        {/*<a onClick={() => { changeSelect('selectLiv'); }} id='selectLivS'>LiveStream</a>*/}
                    </div>
                    <div id='traSuccess'>
                        <div id='selectAll' className='stBody'>
                            <div className='stTraHead'>
                                <p>Description</p>
                                <span>Amount</span>
                            </div>
                            {getData.length > 0 ? (
                                <div>
                                    {getData.map(data =>
                                        <div>
                                            {data.type !== "Add Money" ? (
                                                <div className='stTra'>
                                                    {data.desc ? (<p>{((parser.parseFromString(he.decode(data.desc), 'text/html')).documentElement.textContent)} <br /> ({data.date})</p>) : ('')}
                                                    <span>{data.price} €</span>
                                                </div>
                                            ) : ('')}
                                        </div>
                                    )}
                                </div>
                            ) : ''}
                        </div>
                        <div id='selectSub' className='stBody'>
                            <div className='stTraHead'>
                                <p>Description</p>
                                <span>Amount</span>
                            </div>
                            {getData.length > 0 ? (
                                <div>
                                    {getData.map(data =>
                                        <div>
                                            {data.type == "Subscription" ? (
                                                <div className='stTra'>
                                                    {data.desc ? (<p>{((parser.parseFromString(he.decode(data.desc), 'text/html')).documentElement.textContent)} <br /> ({data.date})</p>) : ('')}
                                                    <span>{data.price} €</span>
                                                </div>
                                            ) : ('')}
                                        </div>
                                    )}
                                </div>
                            ) : ''}
                        </div>
                        <div id='selectMsg' className='stBody'>
                            <div className='stTraHead'>
                                <p>Description</p>
                                <span>Amount</span>
                            </div>
                            {getData.length > 0 ? (
                                <div>
                                    {getData.map(data =>
                                        <div>
                                            {data.type === "Message" ? (
                                                <div className='stTra'>
                                                    {data.desc ? (<p>{((parser.parseFromString(he.decode(data.desc), 'text/html')).documentElement.textContent)} <br /> ({data.date})</p>) : ('Money Add')}
                                                    <span>{data.price} €</span>
                                                </div>
                                            ) : ('')}
                                        </div>
                                    )}
                                </div>
                            ) : ''}
                        </div>
                        <div id='selectPos' className='stBody'>
                            <div className='stTraHead'>
                                <p>Description</p>
                                <span>Amount</span>
                            </div>
                            {getData.length > 0 ? (
                                <div>
                                    {getData.map(data =>
                                        <div>
                                            {data.type === "Buy Post" ? (
                                                <div className='stTra'>
                                                    {data.desc ? (<p>{((parser.parseFromString(he.decode(data.desc), 'text/html')).documentElement.textContent)} <br /> ({data.date})</p>) : ('')}
                                                    <span>{data.price} €</span>
                                                </div>
                                            ) : ('')}
                                        </div>
                                    )}
                                </div>
                            ) : ''}
                        </div>
                        <div id='selectTip' className='stBody'>
                            <div className='stTraHead'>
                                <p>Description</p>
                                <span>Amount</span>
                            </div>
                            {getData.length > 0 ? (
                                <div>
                                    {getData.map(data =>
                                        <div>
                                            {data.type === "Tip" ? (
                                                <div className='stTra'>
                                                    {data.desc ? (<p>{((parser.parseFromString(he.decode(data.desc), 'text/html')).documentElement.textContent)} <br /> ({data.date})</p>) : ('')}
                                                    <span>{data.price} €</span>
                                                </div>
                                            ) : ('')}
                                        </div>
                                    )}
                                </div>
                            ) : ''}
                        </div>
                        <div id='selectFun' className='stBody'>
                            <p>Funds</p>
                        </div>
                        <div id='selectLiv' className='stBody'>
                            <p>LiveStream</p>
                        </div>
                    </div>
                    <div id="traError">

                    </div>
                </div>
                <div id='traPurc' className='selectTra'>
                    <div className='stHeader'>
                        <a className='leftArrow'><img src={require('../assets/icons/leftarrow.webp')} /></a>
                        <h3>Purchases</h3>
                    </div>
                    <div className='stFilter'>
                        <a onClick={() => changeTraType2('prcSuccess')} id='prcSuccessS' className='stFilterActive'>Success</a>
                        <a onClick={() => changeTraType2('prcError')} id='prcErrorS'>Error</a>
                    </div>
                    <div id="prcSuccess">
                        <div id='selectAlls' className='stBody'>
                            <div className='stTraHead'>
                                <p>Description</p>
                                <span>Amount</span>
                            </div>
                            {getData.length > 0 ? (
                                <div>
                                    {getData.map(data =>
                                        <div>
                                            {data.type === "Add Money" ? (
                                                <div className='stTra'>
                                                    {data.desc ? (<p>{((parser.parseFromString(he.decode(data.desc), 'text/html')).documentElement.textContent)} <br /> ({data.date})</p>) : ('')}
                                                    <span>{data.price} €</span>
                                                </div>
                                            ) : ('')}
                                        </div>
                                    )}
                                </div>
                            ) : ''}
                        </div>
                    </div>
                    <div id="prcError"></div>
                </div>

            </div>
        </div>
    )
}
export default Notification;