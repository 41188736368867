import React, { useEffect, useState } from 'react'
import alertify from 'alertifyjs';
import Left from './Left';

let stockimg = localStorage.getItem('stockimg');
function opComment(id) {
    var cArea = document.getElementById('comment' + id);
    if (cArea.style.display == "block") {
        cArea.style.display = "none";
    } else {
        cArea.style.display = "block";
    }
}
function Explore() {
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');
    const [suggestData, setSuggestData] = useState([]);
    const [getPosts, setGetPosts] = useState([]);
    const fetchSuggestData = () => {
        fetch('https://kinkyfans.net/rn-adaptor/l-suggestions?id=' + userid)
            .then(responseSGG => responseSGG.json())
            .then(suggestData => setSuggestData(suggestData))
            .catch(errorSGG => console.error('Fetch error : ', errorSGG));
    }
    const fetchGetPosts = () => {
        fetch('https://kinkyfans.net/rn-adaptor/l-mainpage?&userid=' + userid + '&type=for_you')
            .then(responseGPo => responseGPo.json())
            .then(getPosts => setGetPosts(getPosts))
            .catch(errorGPo => console.error('Fetch error : ', errorGPo));
    }
    useEffect(() => {
        fetchSuggestData();
        fetchGetPosts();
    }, []);

    const postLike = async (userid, postid, dil) => {
        const data = { userid: userid, postid: postid, dil: dil };
        try {
            const response = await fetch('http://kinkyfans.net/rn-adaptor/o-postLike.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            console.log("PHP tarafından dönen yanıt:", plJson);
            fetchGetPosts();
        } catch (error) { console.log("An error :", error); }
    };
    const sendTip = async (userid, dil) => {
        const tipArea = document.getElementById('tipAmount').value;
        const tipComment = document.getElementById('tipComment').value;
        const tipUser = document.getElementById('mtAreaUser').innerHTML;
        const tipPost = document.getElementById('mtAreaPost').innerHTML;
        const data = { userid: userid, dil: dil, tip: tipArea, comment: tipComment, ouser: tipUser, postid: tipPost };
        //console.log(data);
        try {
            const response = await fetch('http://kinkyfans.net/rn-adaptor/o-sendTip.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", plJson);
            fetchGetPosts();
            document.getElementById('modalTip').style.display = "none";
            document.getElementById('tipAmount').value = "";
            document.getElementById('tipComment').value = "";
            if (plJson.status == 0) { alertify.error(plJson.message, 2); }
            if (plJson.status == 1) { alertify.success(plJson.message, 2); }

        } catch (error) { console.log("An error :", error); }
    };
    const getTip = async (userid, postid) => {
        const data = { userid: userid };
        try {
            const response = await fetch('http://kinkyfans.net/rn-adaptor/o-getTip.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const gtJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", gtJson);
            document.getElementById('modalTip').style.display = "flex";
            document.getElementById('mtAreaImg').src = gtJson.pp;
            document.getElementById('mtAreaP').innerHTML = gtJson.name + ' ' + gtJson.surname;
            document.getElementById('mtAreaA').href = '/user-detail' + gtJson.userid;
            document.getElementById('mtAreaA').innerHTML = '@' + gtJson.username;
            document.getElementById('mtAreaUser').innerHTML = userid;
            document.getElementById('mtAreaPost').innerHTML = postid;
        } catch (error) { console.log("An error :", error); }
    };
    const postBook = async (userid, postid, dil) => {
        const data = { userid: userid, postid: postid, dil: dil };
        try {
            const response = await fetch('http://kinkyfans.net/rn-adaptor/o-addBookmarkt.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const pbJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", pbJson);
            fetchGetPosts();
        } catch (error) { console.log("An error :", error); }
    };
    let lastArea = "eaUsers";
    function opArea(area){
        document.getElementById(lastArea+"Tab").classList.remove('eahFilterActive');
        document.getElementById(''+lastArea).style.display = "none";
        document.getElementById(area+"Tab").classList.add('eahFilterActive');
        document.getElementById(''+area).style.display = "block";
        lastArea = area;
    }
    console.log(getPosts);
    return (
        <div>
            
            <div id="modalTip" className="modalTip">
                <a onClick={() => document.getElementById('modalTip').style.display = "none"} className='mmClose'></a>
                <div className='mtArea'>
                    <h5>Send Tip</h5>
                    <span onClick={() => document.getElementById('modalTip').style.display = "none"}>x</span>
                    <div className='mtUserArea'>
                        <img id="mtAreaImg" src={stockimg} />
                        <p id="mtAreaP">Name Surname</p>
                        <a href={'/user-detail/1'} id='mtAreaA'>@username</a>
                    </div>
                    <p id='mtAreaUser'>0</p>
                    <p id='mtAreaPost'>0</p>
                    <div className='mtAmountArea'>
                        <p>Tip Amount</p>
                        <img src={require('../assets/icons/tip.webp')} />
                        <input id="tipAmount" placeholder='Enter Amount' />
                    </div>
                    <div className='mtDescArea'>
                        <p>Message</p>
                        <textarea id='tipComment'></textarea>
                    </div>
                    <div className='mtButtonArea'>
                        <a>Cancel</a>
                        <a onClick={() => sendTip(userid, dil)}>Send Tip</a>
                    </div>
                </div>
            </div>
            <div className='leftAreaC'>
                <Left title="Title" />
            </div>
            <div className='fullAreaC'>
                <div className='exploreArea'>
                    <div className='eaHeader'>
                        <a className='leftArrow'><img src={require('../assets/icons/leftarrow.webp')} /></a>
                        <h3>Explore</h3>
                        <div className='eahFilter'>
                            <a id="eaUsersTab" className="eahFilterActive" onClick={()=>opArea('eaUsers')}><img src={require('../assets/icons/creators.webp')} />Creators</a>
                            <a id="eaPostsTab" onClick={()=>opArea('eaPosts')}><img src={require('../assets/icons/posts.webp')} />Posts</a>
                            <a id="eaVideosTab" onClick={()=>opArea('eaVideos')}><img src={require('../assets/icons/video.webp')} />Video Store</a>
                            {/*<a><img src={require('../assets/icons/hashtags.webp')} />Hashtags</a>
                            <a><img src={require('../assets/icons/live.webp')} />Live</a>*/}
                        </div>
                    </div>
                    <div className='eaBody'>
                        <div id="eaUsers">
                            {suggestData.map(user =>
                                <a href={'/user-detail/'+user.id} key={user.id} className='eaUser'>
                                    <img src={user.pp} />
                                    <div className='eaUserDatas'>
                                        <p>{user.name} {user.surname}</p>
                                        <a href={'/user-detail/' + user.id} className='eaUserLink'>@{user.username}</a>
                                        <div className='eaUserData'>
                                            <a><img src={require('../assets/icons/posts.webp')} />{user.timg}</a>
                                            <a><img src={require('../assets/icons/video.webp')} />{user.tvid}</a>
                                        </div>
                                    </div>
                                </a>
                            )}
                        </div>
                        <div id="eaPosts">
                            {getPosts ?(
                                <div>
                                {getPosts.map(post =>
                                    <div key={post.id}>
                                        {post.image ? (
                                            <div key={post.id} className='eaPost'>
                                                <img src={post.image} />
                                                <div className='eaPostDatas'>
                                                    <div className='eaPostUser'>
                                                        <img src={post.pp} />
                                                        <p>{post.name} {post.surname}</p>
                                                        <a href={'/user-detail/' + post.id} className='eaUserLink'>@{post.username}</a>
                                                        <span><img onClick={()=>window.location.href = '/post/'+post.id } src={require('../assets/icons/threedots.webp')} /></span>
                                                    </div>
                                                    <div className='eaPostFunctions'>
                                                        {post.ulike > 0 ? (
                                                            <a onClick={() => postLike(userid, post.id, dil)} className='eaPostFunction'><img src={require('../assets/icons/heartdoll.webp')} /><p>{post.like}</p></a>
                                                        ) : (
                                                            <a onClick={() => postLike(userid, post.id, dil)} className='eaPostFunction'><img src={require('../assets/icons/heart.webp')} /><p>{post.like}</p></a>
                                                        )}
                                                        <a href={'/post/'+post.id} className='eaPostFunction'><img src={require('../assets/icons/comment.webp')} /></a>
                                                        <a onClick={() => { getTip(post.userid, post.id, post.pp, post.username, post.name, post.surname) }} className='eaPostFunction eaPostTip'><img src={require('../assets/icons/tip.webp')} /> <p>Send Tip</p></a>
                                                        {post.ubook > 0 ? (
                                                            <a onClick={() => postBook(userid, post.id, dil)} className='eaPostFunction eaPostBook'><img src={require('../assets/icons/bookmarkdoll.webp')} /></a>
                                                        ) : (
                                                            <a onClick={() => postBook(userid, post.id, dil)} className='eaPostFunction eaPostBook'><img src={require('../assets/icons/bookmark.webp')} /></a>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ('')}
                                    </div>
                                )}
                            </div>
                            ):('')}
                        </div>
                        <div id="eaVideos">
                            {getPosts ?(
                                <div>
                                {getPosts.map(post =>
                                    <div key={post.id}>
                                        {post.video ? (
                                            <div key={post.id} className='eaPost'>
                                                <video width="100%;" height="400px" controls><source src={post.video} type="video/mp4" /></video>
                                                <div className='eaPostDatas'>
                                                    <div className='eaPostUser'>
                                                        <img src={post.pp} />
                                                        <p>{post.name} {post.surname}</p>
                                                        <a href={'/user-detail/' + post.id} className='eaUserLink'>@{post.username}</a>
                                                        <span><img onClick={()=>window.location.href = '/post/'+post.id } src={require('../assets/icons/threedots.webp')} /></span>
                                                    </div>
                                                    <div className='eaPostFunctions'>
                                                        {post.ulike > 0 ? (
                                                            <a onClick={() => postLike(userid, post.id, dil)} className='eaPostFunction'><img src={require('../assets/icons/heartdoll.webp')} /><p>{post.like}</p></a>
                                                        ) : (
                                                            <a onClick={() => postLike(userid, post.id, dil)} className='eaPostFunction'><img src={require('../assets/icons/heart.webp')} /><p>{post.like}</p></a>
                                                        )}
                                                        <a href={'/post/'+post.id} className='eaPostFunction'><img src={require('../assets/icons/comment.webp')} /></a>
                                                        <a onClick={() => { document.getElementById('tipArea' + post.id).style.display === "block" ? (document.getElementById('tipArea' + post.id).style.display = "none") : (document.getElementById('tipArea' + post.id).style.display = "block") }
                                                        } className='eaPostFunction eaPostTip'><img src={require('../assets/icons/tip.webp')} /> <p>Send Tip</p></a>
                                                        {post.ubook > 0 ? (
                                                            <a onClick={() => postBook(userid, post.id, dil)} className='eaPostFunction eaPostBook'><img src={require('../assets/icons/bookmarkdoll.webp')} /></a>
                                                        ) : (
                                                            <a onClick={() => postBook(userid, post.id, dil)} className='eaPostFunction eaPostBook'><img src={require('../assets/icons/bookmark.webp')} /></a>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ('')}
                                    </div>
                                )}
                            </div>
                            ):('')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Explore;