import React from 'react';
import Slider from 'react-slick';

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, background: "#424242a8", height : '100%', width: '40px', display : 'flex', alignItems : 'Center', justifyContent : 'Center', right : '0px' }}
            onClick={onClick}
        />
    );
}
function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, background: "#424242a8", height : '100%', width: '40px', display : 'flex', alignItems : 'Center', justifyContent : 'Center', left : '0px', zIndex : '9998'}}
            onClick={onClick}
        />
    );
}
const Suggest = ({ datas }) => {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        vertical: false,
        verticalSwiping: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <Slider  {...settings}>
            {datas.map(suggests => (
                <div key = {suggests.id}>
                    <div key={suggests.id} className='rasStory' style={{ float: 'left', width: '100%', background: `url(${suggests.background})`, padding : '0 10px'}}>
                        <div className='rasUserData'>
                            <img src={suggests.pp} />
                        </div>
                    </div>
                </div>
            ))}
        </Slider>
    );
};

export default Suggest;