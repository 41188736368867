import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import alertify from 'alertifyjs';
function Login() {
    let { uniqid } = useParams();
    const [suggestData, setSuggestData] = useState([]);
    let suserid = 1;
    const fetchSuggestData = () => {
        fetch('https://kinkyfans.net/rn-adaptor/l-suggestions?id=' + suserid)
            .then(responseSGG => responseSGG.json())
            .then(suggestData => setSuggestData(suggestData))
            .catch(errorSGG => console.error('Fetch error : ', errorSGG));
    }
    useEffect(() => {
        fetchSuggestData();
    }, []);
    
      //eraseCookie('username'); // 'username' çerezini sil      
    const loginFunc = async (uniqid) => {
        let username = document.getElementById('username').value;
        let password = document.getElementById('password').value;
        if(username == "" || password == ""){alertify.error("Please fill the areas.");}else{
            const data = { username: username, password: password, uniqid: uniqid};
            try {
                const response = await fetch('https://kinkyfans.net/rn-adaptor/functions.php?islem=login', {
                    method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
                });
                if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
                const plJson = await response.json();
                if (plJson.status == 0) { alertify.error(plJson.message, 2); }
                if (plJson.status == 1) { 
                    //console.log(uniqid);
                    alertify.success(plJson.message, 2);
                    setInterval(() => {
                        window.location.href = "/"+plJson.userid;
                    }, 2000);
                }
                console.log("PHP tarafından dönen yanıt:", plJson);
            } catch (error) { console.log("An error :", error); }
        }
    };
    const registerFunc = async () => {
        let regUsername = document.getElementById('regUsername').value;
        let regEmail = document.getElementById('regEmail').value;
        let name = document.getElementById('name').value;
        let surname = document.getElementById('surname').value;
        let regPassword = document.getElementById('regPassword').value;
        let rePassword = document.getElementById('rePassword').value;
        if(regUsername == '' || regEmail == '' || name == '' || surname == '' || regPassword == '' || rePassword == ''  ){alertify.error("Please fill the areas.");}else{
            const data = { username: regUsername, email: regEmail, name : name, surname : surname, password: regPassword, repassword: rePassword};
            try {
                const response = await fetch('https://kinkyfans.net/rn-adaptor/functions.php?islem=register', {
                    method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
                });
                if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
                const plJson = await response.json();
                if (plJson.status == 0) { alertify.error(plJson.message, 2); }
                if (plJson.status == 1) { 
                    alertify.success(plJson.message, 2);
                    document.getElementById('memberOk').style.display="block";
                    document.getElementById('memberOk').innerHTML = plJson.message;
                    
                }
                console.log("PHP tarafından dönen yanıt:", plJson);
            } catch (error) { console.log("An error :", error); }
        }
    };
    function opForm(area){
        if(area == "loginForm"){document.getElementById('signupForm').style.display = "none";}
        if(area == "signupForm"){document.getElementById('loginForm').style.display = "none";}
        let farea = document.getElementById(area);
        if(farea.style.display == "block"){
            farea.style.display = "none";
        }else{
            farea.style.display = "block";
        }
    }
    return (
        <div className='loginArea'>
            <div className='loginAreaTop'>
                <div className='latLogo'>
                    <img src={require('../assets/images/klogo.webp')}/>
                    <a>Explore</a>
                </div>
                <div className='latButton'>
                    <a><span onClick={()=>opForm('loginForm')}>Login</span>
                        <div className='loginForm' id='loginForm'>
                            <div className='lfHeader'>
                                <img src={require('../assets/images/klogo.webp')} />
                                <h3>Login</h3>
                            </div>
                            <div className='lfForm'>
                                <input type='text' id='username' placeholder='Email/Username'  value='info@wk-media.li' />
                                <input type='password' id='password' placeholder='Password' value='deneme123' />
                                <a onClick={()=>loginFunc()}>Login</a>
                                <div className='lfFormCheck'>
                                    <input type='checkbox' />
                                    <span>Stay logged in for 30 Days</span>
                                    <a>Forgot Your Password</a>
                                </div>
                                <div className='lfFormOther'>
                                    <p className='lfSignUp'>Don't have an account yet? <span>Create a free account</span></p>
                                    <h6>or signup with</h6>
                                    <a><img src={require('../assets/icons/copy.webp')} /> Google</a>
                                    <a><img src={require('../assets/icons/copy.webp')} /> X</a>
                                </div>
                            </div>
                        </div>
                        <div className='loginForm' id='signupForm'>
                            <div className='lfHeader'>
                                <img src={require('../assets/images/klogo.webp')} />
                                <h3>Register</h3>
                            </div>
                            <div className='lfForm'>
                            <p id='memberOk'>Membership application has been completed, please confirm your e-mail address.</p>
                                <input type='text' id='regUsername' placeholder='Username'/>
                                <input type='text' id='regEmail' placeholder='Email'/>
                                <input type='text' id='name' placeholder='Name'/>
                                <input type='text' id='surname' placeholder='Surname'/>
                                <input type='password' id='regPassword' placeholder='Password'/>
                                <input type='password' id='rePassword' placeholder='RePassword'/>
                                <a onClick={()=>registerFunc()}>Register</a>
                                
                                <div className='lfFormOther'>
                                    <h6>or signup with</h6>
                                    <a><img src={require('../assets/icons/copy.webp')} /> Google</a>
                                    <a><img src={require('../assets/icons/copy.webp')} /> X</a>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a><span onClick={()=>opForm('signupForm')}>Signup</span>
                        
                    </a>
                </div>
            </div>
            <div className='LoginAreaBanner'>
                <img src={require('../assets/images/lback4.webp')} />
                <h1>Your Favorite Creators</h1>
            </div>
            <div className='loginAreaCreators'>
                <h2>Featured Creators</h2>
                <div className='lacUsers'>
                    <div id="lacUsers">
                        {suggestData.map(user =>
                            <a href={'/user-detail/'+user.id} key={user.id} className='lacUser'>
                                <img src={user.pp} />
                                <div className='lacUserDatas'>
                                    <p>{user.name} {user.surname}</p>
                                    <a href={'/user-detail/' + user.id} className='lacUserLink'>@{user.username}</a>
                                    <div className='lacUserData'>
                                        <a><img src={require('../assets/icons/posts.webp')} />{user.timg}</a>
                                        <a><img src={require('../assets/icons/video.webp')} />{user.tvid}</a>
                                    </div>
                                </div>
                            </a>
                        )}
                    </div>
                    <div className='lacSm'>
                        <a>See More</a>
                    </div>
                </div>
            </div>
            <div className='loginAreaFooter'>
                <div className='lafLogo'>
                    <img src={require('../assets/images/klogo.webp')} />
                </div>
                <div className='lafArea'>
                    <h5>Follow us on :</h5>
                    <a><img src={require('../assets/icons/copy.webp')} /> Instagram</a>
                    <a><img src={require('../assets/icons/copy.webp')} /> Twitter</a>
                    <a><img src={require('../assets/icons/copy.webp')} /> Tiktok</a>
                </div>
                <div className='lafArea'>
                    <h5>Account</h5>
                    <a>Login</a>
                    <a>Register</a>
                </div>
                <div className='lafArea'>
                    <h5>Company</h5>
                    <a>FAQ</a>
                    <a>Privacy Policy</a>
                    <a>Terms of Use</a>
                    <a>Cookie Policy</a>
                </div>
                <div className='lafArea'>
                    <h5>Contact Us</h5>
                    <a><img src={require('../assets/icons/copy.webp')} /> info@kinkyfans.net</a>
                </div>
                <div className='lafCopy'>
                    <p>&copy; 2024 KinkyFans</p>
                </div>
            </div>
        </div>
    )

}
export default Login;