import React, { Component, useEffect, useState } from 'react';
/*import '../assets/css/store.css';*/
import StoreHead from './subComponents/StoreHead';
import StoreFooter from './subComponents/StoreFooter';
function Store() {
    const [userData, setUserData] = useState([]);
    const pp = require('../assets/images/klogo.webp');
    let lastImg = "img1";
    function chImage(gid){
        document.getElementById(gid).classList.add('idlaSelected');document.getElementById(lastImg).classList.remove('idlaSelected');
        document.getElementById('mainImg').src = document.getElementById(gid).src;
        lastImg = gid;
    }
    return (
        <div className='storePage'>
            <StoreHead datas={StoreHead} />

            <div className='containers'>
                <div className='breadcrump'>
                    <a>Homepage</a> <a> > </a> <a>Core By Kink Thigh-to-Chest And Wrist Cuff Restraints</a>
                </div>
                <div className='itemDetails'>
                    <h1>Core By Kink Thigh-to-Chest And Wrist Cuff Restraints</h1>
                    <div className='idLeftArea'>
                        <div className='idlaImages'>
                            <div className='idlaImagesGroup'>
                                <img onClick={()=>chImage('img1')} id='img1' className='idlaSelected' src={require('../assets/images/product1.webp')} />
                                <img onClick={()=>chImage('img2')} id='img2' src={require('../assets/images/product2.webp')} />
                                <img onClick={()=>chImage('img3')} id='img3' src={require('../assets/images/product3.webp')} />
                                <img onClick={()=>chImage('img4')} id='img4' src={require('../assets/images/product4.webp')} />
                                <img onClick={()=>chImage('img5')} id='img5' src={require('../assets/images/product5.webp')} />
                            </div>
                            <div className='idlaMainImage'>
                                <img id='mainImg' src={require('../assets/images/product1.webp')} />
                            </div>
                        </div>
                        <div className='idlaDesc'>
                            <h3>Descriptions</h3>
                            <p>This kink red, 100% genuine leather mouth gag is perfect for muffling your or your partner's cries of pain and pleasure! This gag is padded, fully adjustable, and easy to use and clean. You will be pleasantly aroused at the wearer's full mouth, their restricted speech, and their uncontrollable drooling. This is also a great option for those who like to clench their teeth during play. Give your pillow a break and bite down on this sexy gag instead!</p>

                            <p>PRO TIP: Any mouth gag makes breathing more difficult. Keep the wearer's nose completely clear and remember that a gagged subject should never be left unattended.</p>

                            <p>• 100% Genuine leather</p>
                            <p>• Fully adjustable length of 18" to 22" (46cm - 56cm) with buckle settling on the back of the neck</p>
                            <p>• For all levels of play</p>
                            <p>• Two O-rings to attach accessories</p>

                            <p>Note: Our leather is hand dyed, some variance in colors can occur.</p>

                            <p>Materials: 100% Genuine leather, metal</p>
                            <p>Weight: 4.3 oz (122g)</p>
                            <p>Dimensions: Fully adjustable size range of 18"-22" (46cm - 56cm); gag biting surface approx. 6" (15.2cm); two 1.75" (4.5cm) O-rings</p>
                        </div>
                    </div>
                    <div className='idRightArea'>
                        <p>This seriously hot, leather restraint set is guaranteed to take your restraint play to the next level. Your partner will be helpless when their wrists are easily attached to the ring on the back of the shoulder strap. Use the snap hooks together with the leather bondage straps to achieve the perfect amount of slack.</p>
                        <div className='idraSection'>
                            <label>Color</label>
                            <select>
                                <option value="black">Black</option>
                                <option value="red">Red</option>
                                <option value="white">White</option>
                            </select>
                        </div>
                        <h5>$105.99</h5>
                        <div className='idraFunction'>
                            <input type='number' value='1'/>
                            <a>Add to Cart</a>
                        </div>
                        <a className='morePayment'>More Payment Options</a>
                    </div>
                    <div className='idFullArea'>
                        <h3>You may also like</h3>
                        <div className='idItems'>
                            <div className='idItem'>
                                <img src={require('../assets/images/product1.webp')} />
                                <p>Collars & Leashes </p>
                                <span className='priceNew'>55.56$</span>
                                <span className='priceOld'>66.67$</span>
                            </div>
                        </div>
                        <div className='idItems'>
                            <div className='idItem'>
                                <img src={require('../assets/images/product2.webp')} />
                                <p>Collars & Leashes </p>
                                <span className='priceNew'>55.56$</span>
                                <span className='priceOld'>66.67$</span>
                            </div>
                        </div>
                        <div className='idItems'>
                            <div className='idItem'>
                                <img src={require('../assets/images/product3.webp')} />
                                <p>Collars & Leashes </p>
                                <span className='priceNew'>55.56$</span>
                                <span className='priceOld'>66.67$</span>
                            </div>
                        </div>
                        <div className='idItems'>
                            <div className='idItem'>
                                <img src={require('../assets/images/product4.webp')} />
                                <p>Collars & Leashes </p>
                                <span className='priceNew'>55.56$</span>
                                <span className='priceOld'>66.67$</span>
                            </div>
                        </div>
                        <div className='idItems'>
                            <div className='idItem'>
                                <img src={require('../assets/images/product5.webp')} />
                                <p>Collars & Leashes </p>
                                <span className='priceNew'>55.56$</span>
                                <span className='priceOld'>66.67$</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <StoreFooter datas={StoreFooter} />
        </div>
    )
}
export default Store;
