import React, { Component, useEffect, useState } from 'react';
import './assets/css/style.css';
import './assets/css/core.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Login from './components/Login';
import Body from './components/Body';
import Left from './components/Left';
import User from './components/User';
import NotFound from './NotFound';
import { Container, Row } from 'reactstrap';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Explore from './components/Explore';
import Notification from './components/Notification';
import Message from './components/Message';
import Transactions from './components/Transactions';
import Collections from './components/Collections';
import Profile from './components/Profile';
import Store from './components/Store';
import Categories from './components/Categories';
import ItemDetail from './components/ItemDetail';
import Cart from './components/Cart';
import Checkout from './components/Checkout';
import Post from './components/Post';
import Posts from './components/Posts';
import Apply from './components/Apply';

function App() {
  //let userid = sessionStorage.getItem('userid');

  localStorage.setItem('stockimg', 'https://kinkyfans.net/assets/icons/secret.webp');
  //let stockimg = sessionStorage.getItem('stockimg');

  localStorage.setItem('dil', 'en');
  //let dil = sessionStorage.getItem('dil');
  const [userData, setUserData] = useState([]);
  const fetchUserData = () => {
    fetch('https://kinkyfans.net/rn-adaptor/l-user?id=1')
      .then(responseUD => responseUD.json())
      .then(userData => setUserData(userData))
      .catch(errorUD => console.error('Fetch error : ', errorUD));
  }
  useEffect(() => {
    fetchUserData();
  }, []);
  //console.log(userData);
  function ldMenu(type){
    if(type=="close"){
      document.getElementById('ldMenu').style.left="-300px";
      document.getElementById('windowBlur').style.display="none";
    }
  }
  
  let userid = localStorage.getItem('userid');
  return (
    <div >
      <div className='leftDetailMenu' id='ldMenu'>
        <span className='ldmClose' onClick={()=>ldMenu('close')}>X</span>
        <div className='ldUser'>
          <div className='ldUserInfo'>
            <img src={userData.pp} />
            <p>{userData.name} {userData.surname}</p>
            <a>@{userData.username}</a>
          </div>
          <a href='' className='ldUserData'>
            <h6>{userData.following}</h6>
            <p>Following</p>
          </a>
          <a href='' className='ldUserData'>
            <h6>{userData.followers}</h6>
            <p>Followers</p>
          </a>
        </div>
        <ul className="ldMenu">
          <div className='ldMenuGroup'>
            <li><a href="/"><img src={require('../src/assets/icons/profile.webp')} /> <p> Add Funds</p></a></li>  
          </div>
          <div className='ldMenuGroup'>
            <li><a href={"/user-detail/"+userid}><img src={require('../src/assets/icons/profile.webp')} /> <p> My Profile</p></a></li>
            <li><a href="/collections"><img src={require('../src/assets/icons/collections.webp')} /> <p> Collections</p></a></li>
            <li><a href="/transactions"><img src={require('../src/assets/icons/money.webp')} /> <p> Transactions</p></a></li>
            {userData.isCreator == '1' ? (
            <li><a href="/posts"><img src={require('../src/assets/icons/creators.webp')} /> <p> My Posts</p></a></li>
            ):(
              <li><a href="/apply"><img src={require('../src/assets/icons/creators.webp')} /> <p> Apply as Creator</p></a></li>
            )}
            <li><a href="/profile"><img src={require('../src/assets/icons/filter.webp')} /> <p> Settings</p></a></li>
          </div>
          {/*<div className='ldMenuGroup'>
            <li><a href="/"><img src={require('../src/assets/icons/cards.webp')} /> <p> My Cards(to subscribe)</p></a></li>
          </div>*/}
          <div className='ldMenuGroup'>
            <li><a href="/"><img src={require('../src/assets/icons/lightmode.webp')} /> <p> Light Mode</p></a></li>
            <li><a href="/"><img src={require('../src/assets/icons/support.webp')} /> <p> Support</p></a></li>
          </div>
          <li><a href="/login"><img src={require('../src/assets/icons/logout.webp')} /> <p> Logout</p></a></li>
        </ul>
      </div>
      <Container>
        <div className='windowBlur' id='windowBlur' onClick={()=>ldMenu('close')}></div>
        <Row>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Body />} />
              <Route path="/:guserid" element={<Body />} />
              <Route path="/login" element={<Login />} />
              <Route path="/user-detail/:id" element={<User />} />
              <Route path="/explore" element={<Explore />} />
              <Route path="/notification" element={<Notification />} />
              <Route path="/message" element={<Message />} />
              <Route path="/message/:uniqid" element={<Message />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/collections" element={<Collections />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/post/:id" element={<Post />} />
              <Route path="/posts" element={<Posts />} />
              <Route path="/posts/:uniqid" element={<Posts />} />
              <Route path="/Apply/" element={<Apply />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="/store" element = {<Store />} />
              <Route path="/categories" element = {<Categories />} />
              <Route path="/product" element = {<ItemDetail />} />
              <Route path="/cart" element = {<Cart />} />
              <Route path="/checkout" element = {<Checkout />} />
            </Routes>
          </BrowserRouter>
        </Row>
      </Container>
    </div>
  );
}

export default App;
