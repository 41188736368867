import React from 'react';
import Slider from 'react-slick';
import {Navigate, useNavigate} from 'react-router-dom';

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "transparent", top: "-35px", right : "20px", fontSize : '20px' }}
            onClick={onClick}
        />
    );
}
function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "transparent", top: "-35px", left: "auto", right : "55px" }}
            onClick={onClick}
        />
    );
}
const Suggest = ({ datas }) => {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        vertical: true,
        verticalSwiping: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    
    return (
        <Slider {...settings}>
            {datas.map(suggests => (
                <div key={suggests.id}>
                    <a key={suggests.id} href={'/user-detail/'+suggests.id} className='rasUser' style={{ float: 'left', width: '100%', background: `url(${suggests.background})`, }}>
                        <div className='rasUserCover'></div>
                        <div className='rasUserData'>
                            <img src={suggests.pp} />
                            <p>{suggests.name} {suggests.surname}</p>
                            <span>@{suggests.username}</span>
                        </div>
                    </a>
                </div>
            ))}
        </Slider>
    );
};

export default Suggest;