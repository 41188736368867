import React, { useEffect, useState } from 'react'
import he from 'he';
import { useParams } from 'react-router-dom';
import alertify from 'alertifyjs';
import Left from './Left';
/*const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});*/
function PostDetail() {
    let userid = sessionStorage.getItem('userid');
    let dil = sessionStorage.getItem('dil');
    const [applyData, setApplyData] = useState([]);
    const [getProfile, setGetProfile] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);
    const fetchGetProfile = () => {
        fetch('https://kinkyfans.net/rn-adaptor/l-apply?&userid=' + userid)
            .then(responseGP => responseGP.json())
            .then(getProfile => setGetProfile(getProfile))
            .catch(errorGP => console.error('Fetch error : ', errorGP));
    }
    useEffect(() => {
        fetchGetProfile();
        document.getElementById('applyPersonal').style.display="block";
    }, []);
    console.log(getProfile);
    const dataChange = async (area, value) => {
        if(area == "file"){value = selectedFile}
        const data = { userid: userid, area: area, value: value};
        try {
            const response = await fetch('http://kinkyfans.net/rn-adaptor/o-chApply.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const responseJson = await response.json();
            console.log("PHP tarafından dönen yanıt:", responseJson);
            /*if (responseJson.status == 0) { alertify.error(responseJson.message, 2); }
            if (responseJson.status == 1) { alertify.success(responseJson.message, 2); }*/
        } catch (error) { console.log("An error :", error); }
    };
    const handleFileChange = (event) => {
        console.log(event.target.files[0]);
        setSelectedFile(event.target.files[0]);
        //setSelectedFile(document.getElementById('file').files[0]);
        //dataChange('file', 'file');
        handleSubmit(event);
      };
      const handleSubmit = (event) => {
        event.preventDefault();
        if (selectedFile) {
          const formData = new FormData();
          formData.append('file', selectedFile);formData.append('userid', userid);formData.append('file', 'file');formData.append('area','file');
          fetch('http://kinkyfans.net/rn-adaptor/o-chApply.php', {
            method: 'POST',
            body: formData
          })
          .then(response => {console.log(response);})
          .catch(error => {console.log(error);});
        }
      };
    const [text, setText] = useState([]);
    //function textLoad(){setText(getProfile.bio);}
    const emojis = ["😀", "😁", "😅", "😄", "😂", "🤣", "🥰", "😍", "💑", "😎", "🤔", "😠", "😞", "😪", "😭", "🎈"];
    const addEmoji = (emoji) => { setText(text + emoji); }
    const textChange = (event) => { selectGetir();setText(event.target.value); dataChange('description', document.getElementById('bio').value);}
    const usernameChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, username: document.getElementById('username').value }); dataChange('username', document.getElementById('username').value);};
    const nameChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, name:  document.getElementById('name').value }); dataChange('name', document.getElementById('name').value);};
    const surnameChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, surname:  document.getElementById('surname').value }); dataChange('surname', document.getElementById('surname').value);};
    const birthdayChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, birthday:  document.getElementById('birthday').value }); dataChange('birthday', document.getElementById('birthday').value);};
    const facebookChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, facebook:  document.getElementById('facebook').value }); dataChange('facebook', document.getElementById('facebook').value);};
    const instagramChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, instagram:  document.getElementById('instagram').value }); dataChange('instagram', document.getElementById('instagram').value);};
    const twitterChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, twitter:  document.getElementById('twitter').value }); dataChange('twitter', document.getElementById('twitter').value);};
    const tiktokChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, tiktok:  document.getElementById('tiktok').value }); dataChange('tiktok', document.getElementById('tiktok').value);};
    const snapchatChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, snapchat:  document.getElementById('snapchat').value }); dataChange('snapchat', document.getElementById('snapchat').value);};
    const telegramChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, telegram:  document.getElementById('telegram').value }); dataChange('telegram', document.getElementById('telegram').value);};
    const twitchChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, twitch:  document.getElementById('twitch').value }); dataChange('twitch', document.getElementById('twitch').value);};
    const addressChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, address:  document.getElementById('address').value }); dataChange('address', document.getElementById('address').value);};
    const cityChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, city:  document.getElementById('city').value }); dataChange('city', document.getElementById('city').value);};
    const countryChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, country:  document.getElementById('country').value }); dataChange('country', document.getElementById('country').value);};
    const stateChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, state:  document.getElementById('state').value }); dataChange('state', document.getElementById('state').value);};
    const postcodeChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, post_code:  document.getElementById('post_code').value }); dataChange('postcode', document.getElementById('post_code').value);};
    const ratingChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, rating:  document.getElementById('rating').value }); dataChange('rating', document.getElementById('rating').value);};
    const genderChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, gender:  document.getElementById('gender').value }); dataChange('gender', document.getElementById('gender').value);};
    const sexChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, sex:  document.getElementById('sex').value }); dataChange('sex', document.getElementById('sex').value);};
    const ageChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, age:  document.getElementById('age').value }); dataChange('age_value', document.getElementById('age').value);document.getElementById('ageValue').innerHTML = document.getElementById('age').value};
    const eyeChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, eye:  document.getElementById('eye').value }); dataChange('eye', document.getElementById('eye').value);};
    const hairChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, hair:  document.getElementById('hair').value }); dataChange('hair', document.getElementById('hair').value);};
    const heightChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, height:  document.getElementById('height').value }); dataChange('height', document.getElementById('height').value);};
    const shoeChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, shoe:  document.getElementById('shoe').value }); dataChange('shoe', document.getElementById('shoe').value);document.getElementById('shoeValue').innerHTML = document.getElementById('shoe').value};
    const ethnicityChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, ethnicity:  document.getElementById('ethnicity').value }); dataChange('ethnicity', document.getElementById('ethnicity').value);};
    const tattooChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, tattoo:  document.getElementById('tattoo').value }); dataChange('tattoo', document.getElementById('tattoo').value);};
    const piercingChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, piercing:  document.getElementById('piercing').value }); dataChange('piercing', document.getElementById('piercing').value);};
    const memberfeeChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, memberfee:  document.getElementById('memberfee').value }); dataChange('memberfee', document.getElementById('memberfee').value);};
    const memberfee2Change = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, memberfee2:  document.getElementById('memberfee2').value }); dataChange('memberfee2', document.getElementById('memberfee2').value);};
    const memberfee3Change = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, memberfee3:  document.getElementById('memberfee3').value }); dataChange('memberfee3', document.getElementById('memberfee3').value);};
    const memberfee6Change = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, memberfee6:  document.getElementById('memberfee6').value }); dataChange('memberfee6', document.getElementById('memberfee6').value);};
    function selectGetir(){
        setGetProfile({ rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, 
    });
        setGetProfile({ gender:  document.getElementById('gender').value });
        setGetProfile({ sex:  document.getElementById('sex').value });
        setGetProfile({ eye:  document.getElementById('eye').value });
        setGetProfile({ hair:  document.getElementById('hair').value });
        setGetProfile({ eye:  document.getElementById('eye').value });
        setGetProfile({ ethnicity:  document.getElementById('ethnicity').value });
    }
    if (getProfile.piercing == '1') { document.getElementById('piercing').checked = "true"; }
    if (getProfile.tattoo == '1') { document.getElementById('tattoo').checked = "true"; }let ltabid = 'profile';
    function pdTab(tabid) {
        document.getElementById('profile').style.display = "none";document.getElementById('profileA').classList.remove('editProfileActive');
        document.getElementById('account').style.display = "none";document.getElementById('accountA').classList.remove('editProfileActive');
        document.getElementById('kinkys').style.display = "none";document.getElementById('kinkysA').classList.remove('editProfileActive');
        document.getElementById('notifications').style.display = "none";document.getElementById('notificationsA').classList.remove('editProfileActive');
        document.getElementById(ltabid).style.display = "none"; document.getElementById(tabid).style.display = "block";
        document.getElementById(ltabid + 'A').classList.remove('editProfileActive'); document.getElementById(tabid + 'A').classList.add('editProfileActive');
        ltabid = tabid;
    }
    
    function areaOpen(area){
        document.getElementById('applyPersonal').style.display = "none";
        document.getElementById('applyMailing').style.display = "none";
        document.getElementById('applyAboutme').style.display = "none";
        document.getElementById('applyVerification').style.display = "none";
        document.getElementById(area).style.display = "block";
    }
    return (
        <div>
            <div className='leftAreaC'>
                <Left title="Title" />
            </div>
            <div className='fullAreaC'>
                {/*
                <div className='allCollections'>
                    <div className='acHeader'>
                        <h3>Settings</h3>
                    </div>
                    <div className='editProfile'>
                        <a id='profileA' onClick={() => pdTab('profile')} className='editProfileActive'>Profile<img src={require('../assets/icons/arrow.webp')} /></a>
                        <a id='accountA' onClick={() => pdTab('account')}>Account<img src={require('../assets/icons/arrow.webp')} /></a>
                        <a id='kinkysA' onClick={() => pdTab('kinkys')}>Kinks<img src={require('../assets/icons/arrow.webp')} /></a>
                        <a id='notificationsA' onClick={() => pdTab('notifications')}>Notifications<img src={require('../assets/icons/arrow.webp')} /></a>
                    </div>
                </div>*/}
                <div className='midArea'>
                    <div className='applyArea'>
                        <div className='stHeader'>
                            <h3>Apply as Creator</h3>
                        </div>
                        <div className='applyDetails' id='applyPersonal'>
                            <h3>Personal Information</h3>
                            <div className='adArea'>
                                <label>Username</label>
                                <input className='adaFwInput' id="username" value={getProfile.username} onChange={() => usernameChange()}/>
                            </div>
                            <div className='adArea'>
                                <label>Name</label>
                                <input className='adaFwInput' id="name"  value={getProfile.name} onChange={() => nameChange()}/>
                            </div>
                            <div className='adArea'>
                                <label>Surname</label>
                                <input className='adaFwInput' id="surname"  value={getProfile.surname} onChange={() => surnameChange()}/>
                            </div>
                            <div className='adArea'>
                                <label>Birthday</label>
                                <input type="date" className='adaFwInput' id="birthday"  value={getProfile.birthday} onChange={() => birthdayChange()}/>
                            </div>
                            <div className='adArea'>
                                <label>Facebook</label>
                                <input className='adaFwInput' id="facebook"  value={getProfile.facebook} onChange={() => facebookChange()}/>
                            </div>
                            <div className='adArea'>
                                <label>Instagram</label>
                                <input className='adaFwInput' id="instagram"  value={getProfile.instagram} onChange={() => instagramChange()}/>
                            </div>
                            <div className='adArea'>
                                <label>X(Twitter)</label>
                                <input className='adaFwInput' id="twitter"  value={getProfile.twitter} onChange={() => twitterChange()}/>
                            </div>
                            <div className='adArea'>
                                <label>Tiktok</label>
                                <input className='adaFwInput' id="tiktok"  value={getProfile.tiktok} onChange={() => tiktokChange()}/>
                            </div>
                            <div className='adArea'>
                                <label>Snapchat</label>
                                <input className='adaFwInput' id="snapchat"  value={getProfile.snapchat} onChange={() => snapchatChange()}/>
                            </div>
                            <div className='adArea'>
                                <label>Telegram</label>
                                <input className='adaFwInput' id="telegram"  value={getProfile.telegram} onChange={() => telegramChange()}/>
                            </div>
                            <div className='adArea'>
                                <label>Twitch</label>
                                <input className='adaFwInput' id="twitch"  value={getProfile.twitch} onChange={() => twitchChange()}/>
                            </div>
                            <div className='adAreafw'>
                                <label>Bio</label>
                                <div id={"emoji1"} className='bioEmojiArea'>
                                    {emojis.map((emoji) => (
                                        <a key={emoji} onClick={() => addEmoji(emoji)} style={{ margin: '5px' }}>{emoji}</a>
                                    ))}
                                </div>
                                <div className='bioArea'>
                                    <textarea id={'bio'} value={text} onChange={textChange} placeholder='Enter your message'></textarea>
                                    <a onClick={() => { document.getElementById('emoji1').style.display === "block" ? (document.getElementById('emoji1').style.display = "none") : (document.getElementById('emoji1').style.display = "block") }
                                    } className='emoji'><img src={require("../assets/icons/emoji.webp")} /></a>
                                </div>
                            </div>
                            {/*<div className='adArea'>
                                <label>E-Mail</label>
                                <input className='adaFwInput' id="email" />
                            </div>
                            <div className='adArea'>
                                <label>Phone</label>
                                <input className='adaFwInput' id="phone" />
                            </div>*/}
                            <a id='personalNext' onClick={()=>areaOpen('applyMailing')} className='adaSend'>Next</a>
                        </div>
                        <div className='applyDetails' id='applyMailing'>
                            <h3>Mailing Address</h3>
                            <div className='adAreafw'>
                                <label>Address</label>
                                <input className='adaFwInput' id="address" value={getProfile.address} onChange={() => addressChange()}/>
                            </div>
                            <div className='adArea'>
                                <label>City</label>
                                <input className='adaFwInput' id="city" value={getProfile.city} onChange={() => cityChange()}/>
                            </div>
                            <div className='adArea'>
                                <label>Country</label>
                                <input className='adaFwInput' id="country" value={getProfile.country} onChange={() => countryChange()}/>
                            </div>
                            <div className='adArea'>
                                <label>State/Province</label>
                                <input type="text" className='adaFwInput' id="state" value={getProfile.state} onChange={() => stateChange()}/>
                            </div>
                            <div className='adArea'>
                                <label>Post Code</label>
                                <input type="text" className='adaFwInput' id="post_code" value={getProfile.postcode} onChange={() => postcodeChange()}/>
                            </div>
                            <h3>Member Fee</h3>
                            <div className='adArea'>
                                <label>Member Fee (1 Month)</label>
                                <input className='adaFwInput' id="memberfee" value={getProfile.memberfee} onChange={() => memberfeeChange()}/>
                            </div>
                            <div className='adArea'>
                                <label>Member Fee (2 Month)</label>
                                <input className='adaFwInput' id="memberfee2" value={getProfile.memberfee2} onChange={() => memberfee2Change()}/>
                            </div>
                            <div className='adArea'>
                                <label>Member Fee (3 Month)</label>
                                <input className='adaFwInput' id="memberfee3" value={getProfile.memberfee3} onChange={() => memberfee3Change()}/>
                            </div>
                            <div className='adArea'>
                                <label>Member Fee (6 Month)</label>
                                <input className='adaFwInput' id="memberfee6" value={getProfile.memberfee6} onChange={() => memberfee6Change()}/>
                            </div>
                            <a id='mailingNext' onClick={()=>areaOpen('applyAboutme')} className='adaSend'>Next</a>
                            <a id='mailingBack' onClick={()=>areaOpen('applyPersonal')} className='adaBack'>Back</a>
                        </div>
                        <div className='applyDetails' id='applyAboutme'>
                            <h3>About Me</h3>
                            <div className='adArea'>
                                <label>Rating</label>
                                <select className='adaSelect' id='rating' onChange={() => ratingChange()}>
                                    <option value="0">Choose</option>
                                    {getProfile.rating == "SFW"?(<option selected value='SFW'>SFW</option>):(<option value='SFW'>SFW</option>)}
                                    {getProfile.rating == "NSFW"?(<option selected value='NSFW'>NSFW</option>):(<option value='NSFW'>NSFW</option>)}
                                </select>
                            </div>
                            <div className='adArea'>
                                <label>Gender</label>
                                <select className='adaSelect' id='gender' onChange={() => genderChange()}>
                                    <option value="0">Choose</option>
                                    {getProfile.gender == "Male"?(<option selected value='Male'>Male</option>):(<option value='Male'>Male</option>)}
                                    {getProfile.gender == "Female"?(<option selected value='Female'>Female</option>):(<option value='Female'>Female</option>)}
                                    {getProfile.gender == "Trans"?(<option selected value='Trans'>Trans</option>):(<option value='Trans'>Trans</option>)}
                                    {getProfile.gender == "Non-Binary"?(<option selected value='Non-Binary'>Non-Binary</option>):(<option value='Non-Binary'>Non-Binary</option>)}
                                    {getProfile.gender == "Agency"?(<option selected value='Agency'>Agency</option>):(<option value='Agency'>Agency</option>)}
                                </select>
                            </div>
                            <div className='adArea'>
                                <label>Sexual Orientation</label>
                                <select className='adaSelect' id='sex' onChange={() => sexChange()}>
                                    <option value="0">Choose</option>
                                    {getProfile.sex == "Heterosexual/Straight"?(<option selected value='Heterosexual/Straight'>Heterosexual/Straight</option>):(<option value='Heterosexual/Straight'>Heterosexual/Straight</option>)}
                                    {getProfile.sex == "Homosexual"?(<option selected value='Homosexual'>AgHomosexualency</option>):(<option value='Homosexual'>Homosexual</option>)}
                                    {getProfile.sex == "bisexual"?(<option selected value='bisexual'>bisexual</option>):(<option value='bisexual'>bisexual</option>)}
                                    {getProfile.sex == "Heteroflexible"?(<option selected value='Heteroflexible'>Heteroflexible</option>):(<option value='Heteroflexible'>Heteroflexible</option>)}
                                    {getProfile.sex == "Polysexual"?(<option selected value='Polysexual'>Polysexual</option>):(<option value='Polysexual'>Polysexual</option>)}
                                    {getProfile.sex == "Queer"?(<option selected value='Queer'>Queer</option>):(<option value='Queer'>Queer</option>)}
                                    {getProfile.sex == "Androgynosexual"?(<option selected value='Androgynosexual'>Androgynosexual</option>):(<option value='Androgynosexual'>Androgynosexual</option>)}
                                    {getProfile.sex == "Androsexual"?(<option selected value='Androsexual'>Androsexual</option>):(<option value='Androsexual'>Androsexual</option>)}
                                    {getProfile.sex == "Gray-A"?(<option selected value='Gray-A'>Gray-A</option>):(<option value='Gray-A'>Gray-A</option>)}
                                    {getProfile.sex == "Gynosexual"?(<option selected value='Gynosexual'>Gynosexual</option>):(<option value='Gynosexual'>Gynosexual</option>)}
                                    {getProfile.sex == "Objectumsexual"?(<option selected value='Objectumsexual'>Objectumsexual</option>):(<option value='Objectumsexual'>Objectumsexual</option>)}
                                    {getProfile.sex == "Pansexual/Omnisexual"?(<option selected value='Pansexual/Omnisexual'>Pansexual/Omnisexual</option>):(<option value='Pansexual/Omnisexual'>Pansexual/Omnisexual</option>)}
                                    {getProfile.sex == "Skolisexual"?(<option selected value='Skolisexual'>Skolisexual</option>):(<option value='Skolisexual'>Skolisexual</option>)}
                                </select>
                            </div>
                            <div className='adArea'>
                                <label>Age</label>
                                <p id="ageValue">{getProfile.age}</p>
                                <input type="range" onChange={() => ageChange()} className='adaRangeInput' id="age" min='18' max='80' value={getProfile.age}/>
                            </div>
                            <div className='adArea'>
                                <label>Eye Color</label>
                                <select className='adaSelect' id='eye' onChange={() => eyeChange()}>
                                    <option value="0">Choose</option>
                                    {getProfile.eye == "Amber"?(<option selected value='Amber'>Amber</option>):(<option value='Amber'>Amber</option>)}
                                    {getProfile.eye == "Blue"?(<option selected value='Blue'>Blue</option>):(<option value='Blue'>Blue</option>)}
                                    {getProfile.eye == "Brown"?(<option selected value='Brown'>Brown</option>):(<option value='Brown'>Brown</option>)}
                                    {getProfile.eye == "Green"?(<option selected value='Green'>Green</option>):(<option value='Green'>Green</option>)}
                                    {getProfile.eye == "Grey"?(<option selected value='Grey'>Grey</option>):(<option value='Grey'>Grey</option>)}
                                    {getProfile.eye == "Hazel"?(<option selected value='Hazel'>Hazel</option>):(<option value='Hazel'>Hazel</option>)}
                                    {getProfile.eye == "Red"?(<option selected value='Red'>Red</option>):(<option value='Red'>Red</option>)}
                                    {getProfile.eye == "Violet"?(<option selected value='Violet'>Violet</option>):(<option value='Violet'>Violet</option>)}
                                    {getProfile.eye == "Mixed"?(<option selected value='Mixed'>Mixed</option>):(<option value='Mixed'>Mixed</option>)}
                                </select>
                            </div>
                            <div className='adArea'>
                                <label>Hair Color</label>
                                <select className='adaSelect' id='hair' onChange={() => hairChange()}>
                                    <option value="0">Choose</option>
                                    {getProfile.hair == "Black"?(<option selected value='Black'>Black</option>):(<option value='Black'>Black</option>)}
                                    {getProfile.hair == "Blonde"?(<option selected value='Blonde'>Blonde</option>):(<option value='Blonde'>Blonde</option>)}
                                    {getProfile.hair == "Dark Blonde"?(<option selected value='Dark Blonde'>Dark Blonde</option>):(<option value='Dark Blonde'>Dark Blonde</option>)}
                                    {getProfile.hair == "Platinum Blonde"?(<option selected value='Platinum Blonde'>Platinum Blonde</option>):(<option value='Platinum Blonde'>Platinum Blonde</option>)}
                                    {getProfile.hair == "Brunette"?(<option selected value='Brunette'>Brunette</option>):(<option value='Brunette'>Brunette</option>)}
                                    {getProfile.hair == "Dark Brown"?(<option selected value='Dark Brown'>Dark Brown</option>):(<option value='Dark Brown'>Dark Brown</option>)}
                                    {getProfile.hair == "Red Head"?(<option selected value='Red Head'>Red Head</option>):(<option value='Red Head'>Red Head</option>)}
                                    {getProfile.hair == "Auburn"?(<option selected value='Auburn'>Auburn</option>):(<option value='Auburn'>Auburn</option>)}
                                    {getProfile.hair == "Blue"?(<option selected value='Blue'>Blue</option>):(<option value='Blue'>Blue</option>)}
                                    {getProfile.hair == "Grey"?(<option selected value='Grey'>Grey</option>):(<option value='Grey'>Grey</option>)}
                                    {getProfile.hair == "Pink"?(<option selected value='Pink'>Pink</option>):(<option value='Pink'>Pink</option>)}
                                    {getProfile.hair == "Purple"?(<option selected value='Purple'>Purple</option>):(<option value='Purple'>Purple</option>)}
                                    {getProfile.hair == "Orange"?(<option selected value='Orange'>Orange</option>):(<option value='Orange'>Orange</option>)}
                                    {getProfile.hair == "Many"?(<option selected value='Many'>Many</option>):(<option value='Many'>Many</option>)}
                                </select>
                            </div>
                            <div className='adArea'>
                                <label>Height(cm)</label>
                                <input type="text" className='adaFwInput' id="height"  onChange={() => heightChange()} value={getProfile.height}/>
                            </div>
                            <div className='adArea'>
                                <label>Shoe Size</label>
                                <p id="shoeValue"> {getProfile.shoe}</p>
                                <input type="range" onChange={() => shoeChange()} className='adaRangeInput' id="shoe" min='4' max='14'  value={getProfile.shoe}/>
                            </div>
                            <div className='adArea'>
                                <label>Ethnicity</label>
                                <select className='adaSelect' id='ethnicity' onChange={() => ethnicityChange()}>
                                    <option value="0">Choose</option>
                                    {getProfile.ethnicity == "White/Caucasian"?(<option selected value='White/Caucasian'>White/Caucasian</option>):(<option value='White/Caucasian'>White/Caucasian</option>)}
                                    {getProfile.ethnicity == "Latino/Hispanic"?(<option selected value='Latino/Hispanic'>Latino/Hispanic</option>):(<option value='Latino/Hispanic'>Latino/Hispanic</option>)}
                                    {getProfile.ethnicity == "Asian"?(<option selected value='Asian'>Asian</option>):(<option value='Asian'>Asian</option>)}
                                    {getProfile.ethnicity == "Black/Ebony"?(<option selected value='Black/Ebony'>Black/Ebony</option>):(<option value='Black/Ebony'>Black/Ebony</option>)}
                                    {getProfile.ethnicity == "Native American/Alaskan"?(<option selected value='Native American/Alaskan'>Native American/Alaskan</option>):(<option value='Native American/Alaskan'>Native American/Alaskan</option>)}
                                    {getProfile.ethnicity == "Pacific Islander"?(<option selected value='Pacific Islander'>Pacific Islander</option>):(<option value='Pacific Islander'>Pacific Islander</option>)}
                                    {getProfile.ethnicity == "Middle Eastern"?(<option selected value='Middle Eastern'>Middle Eastern</option>):(<option value='Middle Eastern'>Middle Eastern</option>)}
                                    {getProfile.ethnicity == "East Indian"?(<option selected value='East Indian'>East Indian</option>):(<option value='East Indian'>East Indian</option>)}
                                    {getProfile.ethnicity == "Other"?(<option selected value='Other'>Other</option>):(<option value='Other'>Other</option>)}
                                </select>
                            </div>
                            <div className='adAreaSwitch'>
                                <label>Tattoos</label>
                                <input className="kink-switch" id='tattoo' type="checkbox"  onChange={() => tattooChange()}/>
                            </div>
                            <div className='adAreaSwitch'>
                                <label>Piercing</label>
                                <input className="kink-switch" id='piercing' type="checkbox"  onChange={() => piercingChange()}/>
                            </div>
                            <a id='aboutmeNext' onClick={()=>areaOpen('applyVerification')} className='adaSend'>Next</a>
                            <a id='aboutmeBack' onClick={()=>areaOpen('applyMailing')} className='adaBack'>Back</a>
                        </div>
                        <div className='applyDetails' id='applyVerification'>
                            <h3>Identity Verification</h3>
                            <div className='adArea'>
                                <input type="file" id='file' onChange={(event)=>{handleFileChange(event);}} className='adaFile'/>
                            </div>
                            <a onClick={()=>window.location.href='profile'} className='adaSend'>Send</a>
                            <a id='aboutmeBack' onClick={()=>areaOpen('applyAboutme')} className='adaBack'>Back</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default PostDetail;