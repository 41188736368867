import React, { useEffect, useState } from 'react'
import {useParams } from 'react-router-dom';
import he from 'he';
import alertify from 'alertifyjs';
import Left from './Left';
function Profile({ match }) {
    let { uniqid } = useParams();
    let dil = localStorage.getItem('dil');
    let stockimg = localStorage.getItem('stockimg');
    let userid = localStorage.getItem('userid');
    const [userData, setUserData] = useState([]);
    const [kinksData, setKinksData] = useState([]);
    const fetchUserData = () => {
        fetch('https://kinkyfans.net/rn-adaptor/l-myProfile?id=' + userid)
            .then(responseUD => responseUD.json())
            .then(userData => setUserData(userData))
            .catch(errorUD => console.error('Fetch error : ', errorUD));
    }
    const fetchKinksData = () => {
        fetch('https://kinkyfans.net/rn-adaptor/l-kinks?id=' + userid)
            .then(responseKinks => responseKinks.json())
            .then(kinksData => setKinksData(kinksData))
            .catch(errorKinks => console.error('Fetch error : ', errorKinks));
    }
    useEffect(() => {
        fetchUserData();
        fetchKinksData();
    }, []);
    //console.log(kinksData);

    let ltabid = 'profile';
    function pdTab(tabid) {
        document.getElementById('profile').style.display = "none";document.getElementById('profileA').classList.remove('editProfileActive');
        document.getElementById('account').style.display = "none";document.getElementById('accountA').classList.remove('editProfileActive');
        document.getElementById('kinkys').style.display = "none";document.getElementById('kinkysA').classList.remove('editProfileActive');
        document.getElementById('notifications').style.display = "none";document.getElementById('notificationsA').classList.remove('editProfileActive');
        document.getElementById(ltabid).style.display = "none"; document.getElementById(tabid).style.display = "block";
        document.getElementById(ltabid + 'A').classList.remove('editProfileActive'); document.getElementById(tabid + 'A').classList.add('editProfileActive');
        ltabid = tabid;
    }
    let lptabid = '';
    function pdaTab(ptabid) {
        if (lptabid != "") { document.getElementById(lptabid).style.display = "none"; }
        if (ptabid == lptabid) { document.getElementById(ptabid).style.display = "none"; lptabid = ""; } else { document.getElementById(ptabid).style.display = "block"; lptabid = ptabid; }
    }
    const kinkClick= async (kid) => {
        const data = {kid:kid, userid: userid, dil: dil };
        try {
            const response = await fetch('http://kinkyfans.net/rn-adaptor/o-selectKinks.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const responseJson = await response.json();
            console.log("PHP tarafından dönen yanıt:", responseJson);
            if (responseJson.status == "1") { document.getElementById('titles'+responseJson.kid).classList.remove("pdKinksActive"); } else { document.getElementById('titles'+responseJson.kid).classList.add("pdKinksActive"); }
            fetchKinksData();
        } catch (error) { console.log("An error :", error); }
    }
    const chData = async (userid, area, type, dil) => {
        let value1 = "";
        let value2 = "";
        if (type == "notify") {
            value1 = document.getElementById(area).checked;
        } else if (type == "profile") {
            value1 = document.getElementById('name').value;
            value2 = document.getElementById('bio').value;
        }else if(type == "username") {
            value1 = document.getElementById('username').value;
        }else if(type == "email") {
            value1 = document.getElementById('email').value;
        }else if(type == "phone") {
            value1 = document.getElementById('phone').value;
        }else if(type == "password") {
            value1 = document.getElementById('password').value;
        }else {
            value1 = "";
        }
        const data = { userid: userid, area: area, value1: value1, value2: value2, type: type, dil: dil };
        try {
            const response = await fetch('http://kinkyfans.net/rn-adaptor/o-chData.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const responseJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", responseJson);
            if (responseJson.status == 0) { alertify.error(responseJson.message, 2); }
            if (responseJson.status == 1) { alertify.success(responseJson.message, 2); }
        } catch (error) { console.log("An error :", error); }
    };
    const delAccount = async (userid, dil) => {
        let delmail = document.getElementById('delmail').value;
        let delpass = document.getElementById('delpass').value;
        const data = { userid: userid, delmail: delmail, delpass: delpass, dil: dil };
        try {
            const response = await fetch('http://kinkyfans.net/rn-adaptor/f-mails.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const responseJson = await response.json();
            console.log("PHP tarafından dönen yanıt:", responseJson);
            if (responseJson.status == 0) { alertify.error(responseJson.message, 2); }
            if (responseJson.status == 1) { alertify.success(responseJson.message, 2); }
        } catch (error) { console.log("An error :", error); }
    };
    if (userData.nfollow == '1') { document.getElementById('nfollow').checked = "true"; }
    if (userData.nsystem == '1') { document.getElementById('nsystem').checked = "true"; }
    if (userData.nmessage == '1') { document.getElementById('nmessage').checked = "true"; }
    if (userData.nsubscription == '1') { document.getElementById('nsubscription').checked = "true"; }
    if (userData.ntip == '1') { document.getElementById('ntip').checked = "true"; }
    if (userData.npostpay == '1') { document.getElementById('npostpay').checked = "true"; }
    if (userData.nmessagepay == '1') { document.getElementById('nmessagepay').checked = "true"; }
    if (userData.naddmoney == '1') { document.getElementById('naddmoney').checked = "true"; }
    if (userData.nwithraw == '1') { document.getElementById('nwithraw').checked = "true"; }

    //username = userData.username;
    const nameChange = (event) => {setUserData({ pp : userData.pp, background: userData.background, name: event.target.value });};
    const bioChange = (event) => {setUserData({ pp : userData.pp, background: userData.background, description: event.target.value });};
    const usernameChange = (event) => {setUserData({ pp : userData.pp, background: userData.background, username: event.target.value });};
    const mailChange = (event) => {setUserData({ pp : userData.pp, background: userData.background, mail: event.target.value });};
    const phoneChange = (event) => {setUserData({ pp : userData.pp, background: userData.background, phone: event.target.value });};

    return (
        <div>
            <div className='leftAreaC'>
                <Left title="Title" />
            </div>
            <div className='fullAreaC'>
                <div className='allCollections'>
                    <div className='acHeader'>
                        {/*<a className='leftArrow'><img src={require('../assets/icons/leftarrow.webp')} /></a>*/}
                        <h3>Settings</h3>
                    </div>

                    <div className='editProfile'>
                        <a id='profileA' onClick={() => pdTab('profile')} className='editProfileActive'>Profile<img src={require('../assets/icons/arrow.webp')} /></a>
                        <a id='accountA' onClick={() => pdTab('account')}>Account<img src={require('../assets/icons/arrow.webp')} /></a>
                        <a id='kinkysA' onClick={() => pdTab('kinkys')}>Kinks<img src={require('../assets/icons/arrow.webp')} /></a>
                        <a id='notificationsA' onClick={() => pdTab('notifications')}>Notifications<img src={require('../assets/icons/arrow.webp')} /></a>
                    </div>
                </div>
                <div className='nowCollections' id="profile">
                    <div className='profileDetail'>
                        <div className='pdHeader'>
                            <a className='leftArrow'><img src={require('../assets/icons/leftarrow.webp')} /></a>
                            <h3>Profile</h3>
                        </div>
                        <div className='pdDetails'>
                            <div className='pdBackground'>
                                <img className='pdBackgroundImg' src={userData.background} />
                                <div className='pdBackgroundCover'></div>
                                <a> <img src={require('../assets/icons/photo.webp')} /> Edit Image</a>
                            </div>
                            <div className='pdPP'>
                                <img className='pdPPIMG' src={userData.pp} />
                                <div className='pdPPCover'></div>
                                <a><img src={require('../assets/icons/photo.webp')} /></a>
                            </div>
                            <div className='pdDatas'>
                                <div className='pdData'>
                                    <label>Display Name</label>
                                    <input id='name' value={userData.username} onChange={nameChange} />
                                </div>
                                <div className='pdData'>
                                    <label>Bio</label>
                                    <textarea id='description' value={userData.description} onChange={bioChange} />
                                </div>
                            </div>
                            <a onClick={() => chData(userid, 'profile', 'profile', dil)} className='pdSave'>Save</a>
                        </div>
                    </div>
                </div>
                <div className='nowCollections' id="account">
                    <div className='profileDetail'>
                        <div className='pdHeader'>
                            <a className='leftArrow'><img src={require('../assets/icons/leftarrow.webp')} /></a>
                            <h3>Account</h3>
                        </div>
                        <div className='pdDetails'>
                            <div className='pdAccount'>
                                <h5>Account Info</h5>
                                <p onClick={() => pdaTab('pdaUsername')}>Username<img src={require('../assets/icons/arrow.webp')} /></p>
                                <div className='pdaDatas' id="pdaUsername">
                                    <div className='pdaData'>
                                        <label>Username</label>
                                        <input id='username' type='text' value={userData.username} onChange={usernameChange}></input>
                                    </div>
                                    <a onClick={()=>chData(userid, 'username', 'username', dil)}>Save</a>
                                </div>
                                <p onClick={() => pdaTab('pdaEmail')}>Email<img src={require('../assets/icons/arrow.webp')} /></p>
                                <div className='pdaDatas' id="pdaEmail">
                                    <div className='pdaData'>
                                        <label>Email</label>
                                        <input id='email' type='text' value={userData.mail}  onChange={mailChange}/>
                                    </div>
                                    <a onClick={()=>chData(userid, 'email', 'email', dil)}>Save</a>
                                </div>
                                <p onClick={() => pdaTab('pdaPhone')}>Phone Number<img src={require('../assets/icons/arrow.webp')} /></p>
                                <div className='pdaDatas' id="pdaPhone">
                                    <div className='pdaData'>
                                        <label>Phone</label>
                                        <input id='phone' type='text' value={userData.phone}  onChange={phoneChange}/>
                                    </div>
                                    <a onClick={()=>chData(userid, 'phone', 'phone', dil)}>Save</a>
                                </div>
                                <h5>Security</h5>
                                <p onClick={() => pdaTab('pdaPassword')}>Password<img src={require('../assets/icons/arrow.webp')} /></p>
                                <div className='pdaDatas' id="pdaPassword">
                                    <div className='pdaData'>
                                        <label>Password</label>
                                        <input id="password" type='password' />
                                    </div>
                                    <a onClick={()=>chData(userid, 'password', 'password', dil)}>Save</a>
                                </div>
                                <h5>Account Management</h5>
                                <p onClick={() => pdaTab('pdaDelete')}>Delete Account<img src={require('../assets/icons/arrow.webp')} /></p>
                                <div className='pdaDatas' id="pdaDelete">
                                    <div className='pdaData'>
                                        <label>Email</label>
                                        <input id='delmail' type='text' />
                                    </div>
                                    <div className='pdaData'>
                                        <label>Password</label>
                                        <input id='delpass' type='password' />
                                    </div>
                                    <a onClick={()=>delAccount(userid, dil)}>Save</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='nowCollections' id="kinkys">
                    <div className='profileDetail'>
                        <div className='pdHeader'>
                            <a className='leftArrow'><img src={require('../assets/icons/leftarrow.webp')} /></a>
                            <h3>My Kinks</h3>
                        </div>
                        { kinksData ? (
                        <div className='pdKinks'>
                            {kinksData.map(firstLetter =>
                                <div key={firstLetter.id}>
                                    <h5>{firstLetter.title}</h5>
                                    {firstLetter.titles.map ( titles => 
                                    <div key={titles.id}>
                                        {titles.selected == 1 ? (
                                            <a className='pdKinksActive' id={'titles'+titles.id} onClick={() => kinkClick(titles.id)}>{titles.title}</a>
                                        ):(
                                            <a id={'titles'+titles.id} onClick={() => kinkClick(titles.id)}>{titles.title}</a>
                                        )}
                                    </div>
                                        
                                    )}        
                                </div>
                            )}
                        </div>
                        ):''}
                    </div>
                </div>
                <div className='nowCollections' id="notifications">
                    <div className='profileDetail'>
                        <div className='pdHeader'>
                            <a className='leftArrow'><img src={require('../assets/icons/leftarrow.webp')} /></a>
                            <h3>Notifications</h3>
                        </div>
                        <div className='pdNotifications'>
                            <div className='pdNotification'>
                                <span>Follow Notifications</span>
                                <input className="kink-switch" onChange={() => chData(userid, 'nfollow', 'notify', dil)} id='nfollow' type="checkbox" />
                            </div>
                            <div className='pdNotification'>
                                <span>System Notification</span>
                                <input className="kink-switch" onChange={() => chData(userid, 'nsystem', 'notify', dil)} id='nsystem' type="checkbox" />
                            </div>
                            <div className='pdNotification'>
                                <span>Message Notification</span>
                                <input className="kink-switch" onChange={() => chData(userid, 'nmessage', 'notify', dil)} id='nmessage' type="checkbox" />
                            </div>
                            <div className='pdNotification'>
                                <span>Subscription Notification</span>
                                <input className="kink-switch" onChange={() => chData(userid, 'nsubscription', 'notify', dil)} id='nsubscription' type="checkbox" />
                            </div>
                            <div className='pdNotification'>
                                <span>Tip Notification</span>
                                <input className="kink-switch" onChange={() => chData(userid, 'ntip', 'notify', dil)} id='ntip' type="checkbox" />
                            </div>
                            <div className='pdNotification'>
                                <span>Post Pay Notification</span>
                                <input className="kink-switch" onChange={() => chData(userid, 'npostpay', 'notify', dil)} id='npostpay' type="checkbox" />
                            </div>
                            <div className='pdNotification'>
                                <span>Message Pay Notification</span>
                                <input className="kink-switch" onChange={() => chData(userid, 'nmessagepay', 'notify', dil)} id='nmessagepay' type="checkbox" />
                            </div>
                            <div className='pdNotification'>
                                <span>Money Add Notification</span>
                                <input className="kink-switch" onChange={() => chData(userid, 'naddmoney', 'notify', dil)} id='naddmoney' type="checkbox" />
                            </div>
                            <div className='pdNotification'>
                                <span>Withraw Notification</span>
                                <input className="kink-switch" onChange={() => chData(userid, 'nwithraw', 'notify', dil)} id='nwithraw' type="checkbox" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Profile;