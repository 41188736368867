import React, { Component, useEffect, useState } from 'react';
/*import '../assets/css/store.css';*/
function menuOp(type, mid){
    if(type == "open"){document.getElementById(mid).style.display="block";}else{document.getElementById(mid).style.display="none";}
}
function Header() {
    const [userData, setUserData] = useState([]);
    const pp = require('../../assets/images/klogo.webp');
    return (
        <div className='storeHeader'>
            <div className='storeHeaderBand'>
                <a><b>Free Us Shipping</b> over $69 | <b>Discreet</b> Shipping and Billing</a>
            </div>
            <div className='storeHeaderTopMenu'>
                <div className='containers'>
                    <div className='spaceBetween'>
                        <div className='shmLogo'>
                            <img src={pp} />
                        </div>
                        <div className='shmSearch'>
                            <input placeholder='What are you looking for?' />
                            <img src={require('../../assets/icons/search.webp')} />
                        </div>
                        <div className='shmFunction'>
                            <a><img src={require('../../assets/icons/profile.webp')} /></a>
                            <a><img src={require('../../assets/icons/cart.webp')} /><span>3</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='storeHeaderMenu'>
                <div className='containers'>
                    <div className='shmCenter'>
                        <a>Just Getting Started</a>
                        <a>Choose Your Path</a>
                        <a>Find Your Fetish</a>
                        <a onMouseOver={()=>menuOp('open','menu1')} onMouseOut={()=>menuOp('close','menu1')} >For Men
                            <div id='menu1' className='shmSubMenu'>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                            </div>
                        </a>
                        <a onMouseOver={()=>menuOp('open','menu2')} onMouseOut={()=>menuOp('close','menu2')} >Explore It All
                            <div id='menu2' className='shmSubMenu'>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                            </div>
                        </a>
                        <a>Sale</a>
                        <a>Help</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header;
